/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import '../_styles/PointDetail.css';


const DetailsPoint = ({ point, colors, isProject }) => {

  return (
    <div className="info-point">
      <h3>{point.name}</h3>
      <p>{point.address}</p>

      {!isProject && (
        <>
      <div className="routes">
        <div className="icons flex">
          <div className="item">
            <img src={colors?.infowindowTime0?.['background-image']} />
            <h5 className="ml-2">{point.walk} min</h5>
          </div>

          <div className="ml-4 item">
          <img src={colors?.infowindowTime1?.['background-image']} />
            <h5 className="ml-2">{point.bike} min</h5>
          </div>

          <div className="ml-4 item">
          <img src={colors?.infowindowTime2?.['background-image']} />
            <h5 className="ml-2">{point.car} min</h5>
          </div>
        </div>
      </div>
      </>
      )}
    </div>

  );
};

export default DetailsPoint;
