import axios from 'axios'
import config from '../config';

export const getLotsByProjectPhase = async (projectId, phase) => {
    const response = await axios.get(
        `${config.API_URL}/lots/${projectId}/${phase}`, {
          headers: {
            'apikey': config.API_KEY,
          }
        }
    );
    if(!response) return new Error('Data not found')

    return response?.data
}

export const getHomesByProject = async (projectId) => {
  const response = await axios.get(
      `${config.API_URL}/homes/byproject/${projectId}/`, {
        headers: {
          'apikey': config.API_KEY,
        }
      }
  );
  if(!response) return new Error('Data not found')

  return response?.data
}