const DB_NAME = 'zentro_app';
const DB_VERSION = 1;
const STORE_NAME = 'projects';


const openDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);
  
      request.onupgradeneeded = () => {
        const db = request.result;
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: 'id' });
        }
      };
  
      request.onsuccess = () => {
        resolve(request.result);
      };
  
      request.onerror = () => {
        reject(request.error);
      };
    });
  };
export const saveOrUpdateProject = async (project) => {
    try {
      const db = await openDB();
      const transaction = db.transaction(STORE_NAME, 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
  
      // Obtener el proyecto existente
      const existingProject = await new Promise((resolve, reject) => {
        const request = store.get(project.id);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });
  
      if (existingProject) {
        // Fusiona los datos del proyecto existente con los nuevos
        const updatedProject = { ...existingProject, ...project };
        const request = store.put(updatedProject);
  
        return new Promise((resolve, reject) => {
          request.onsuccess = () => resolve();
          request.onerror = () => reject(request.error);
        });
      } else {
        // Si el proyecto no existe, simplemente lo agrega
        const request = store.add(project);
  
        return new Promise((resolve, reject) => {
          request.onsuccess = () => resolve();
          request.onerror = () => reject(request.error);
        });
      }
    } catch (error) {
      console.error('Error saving or updating project:', error);
      throw error;
    }
  };
// Obtiene todos los proyectos de IndexedDB
export const getProjects = async () => {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, 'readonly');
  const store = transaction.objectStore(STORE_NAME);
  const projects = [];
  
  return new Promise((resolve, reject) => {
    const request = store.openCursor();
    
    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        projects.push(cursor.value);
        cursor.continue();
      } else {
        resolve(projects);
      }
    };
    
    request.onerror = () => reject(request.error);
  });
};

export const getProjectById = async (id) => {
    const db = await openDB();
    const transaction = db.transaction(STORE_NAME, 'readonly');
    const store = transaction.objectStore(STORE_NAME);
  
    return new Promise((resolve, reject) => {
      const request = store.get(id);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => {
        console.error('Error while getting project by ID:', request.error);
        reject(request.error);
      };
    });
  };
