import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import axios from 'axios';
import config from './config';
import Project from './components/Project';
import { getProjects, saveOrUpdateProject } from './db';

// Función para obtener datos del proyecto desde la API
const fetchProjectDataFromAPI = async (projectId) => {
  const response = await axios.get(
    `${config.API_URL}/project/${projectId}`, {
      headers: {
        'apikey': config.API_KEY,
      }
    }
  );
  return response.data;
};

// Hook para obtener datos del proyecto
const useProjectData = (projectId) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAndSaveProjectData = useCallback(async () => {
    try {
      // Intenta obtener el proyecto de IndexedDB
      const storedProjects = await getProjects();
      const currentProject = storedProjects.find(
        (project) => project.id === projectId && project.fonts.length > 0
      );
  
      if (currentProject) {
        setProject(currentProject);
      } else {
        // Si el proyecto no existe, lo guarda como nuevo
        const projectData = await fetchProjectDataFromAPI(projectId);
  
        // Guarda el nuevo proyecto en IndexedDB
        await saveOrUpdateProject({
          id: projectId,
          data: projectData.project_data,
          sketch_style: projectData.sketch_style,
          map: projectData.map,
          fonts: projectData.fonts,
          styles: projectData.styles,
          content: projectData.content
        });
  
        setProject(projectData);
      }
    } catch (err) {
      console.error('Error while fetching and saving project data:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchAndSaveProjectData();
    }
  }, [projectId, fetchAndSaveProjectData]);

  return { project, loading, error };
};

const ProjectWrapper = () => {
  const { projectId } = useParams();
  const { project, loading, error } = useProjectData(projectId);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <Project project={project} />
  );
};

// Componente principal de la aplicación
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/project/:projectId/*" element={<ProjectWrapper />} />
      </Routes>
    </Router>
  );
};

export default App;
