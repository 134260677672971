import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useEffect, useRef, useState } from "react";
import { formatStyles } from "../../../../plugins/FormatStyle";
import BtnFavourite from "./BtnFavourite";


const ViewHome = ({styles, setShowHome, setCurrentHome, favorites, toggleFavorite, currentHome}) => {

    const [elevationSelected, setElevationSelected] = useState(currentHome.elevations[0])
    const [loadImage, setLoadImage] = useState(false);
    const sliderRef = useRef();

    // useEffect(()=>{
    //     console.log(elevationSelected)
    // }, [elevationSelected])

    const controllerElevation = (e, index) =>{
        setElevationSelected(e)
        const imageSlider = sliderRef.current?.splide;

        if(imageSlider){
            imageSlider.go(index)
        }
    }

    return (
        <div className="absolute top-0 left-0 w-full h-full z-50 grid grid-cols-[25%,75%]">
            

            <div className="max-h-[100dvh] p-6 flex flex-col justify-end" style={formatStyles(styles.search[1])}>
                <div className="flex flex-row gap-3 pb-5 mb-5 border-b border-b-black">
                <BtnFavourite favorites={favorites} toggleFavorite={toggleFavorite} home_id={currentHome.id} styles={styles} className="mt-3" />
                    <div className="flex flex-col gap-2">
                    <h3 className="uppercase text-4xl font-bold">{currentHome.name}</h3>
                    <p className="text-[15px]">ELEVATION {elevationSelected.name}</p>
                    </div>
                </div>
                <div className="flex flex-row gap-3 mb-4">
                    <i className="locationLot size-6 block bg-[length:60%] bg-no-repeat bg-center bg-[#4d4d4d] rounded-full cursor-pointer" style={formatStyles(styles.locationLot)}></i>
                    <span>View on sitemap</span>
                </div>
                <div className="pt-1 text-[10px] grid grid-cols-[1fr_1fr_1fr_auto] gap-1 lowrise__view__info">
                    <div className="homecard__info flex flex-col">
                        <span>BEDS</span>
                        <span>{elevationSelected.bed || currentHome.bed}</span>
                    </div>
                    <div className="homecard__info flex flex-col">
                        <span>BATHS</span>
                        <span>{elevationSelected.bath || currentHome.bath}</span>
                    </div>
                    <div className="homecard__info flex flex-col">
                        <span>PARKING</span>
                        <span>{elevationSelected.garage || currentHome.garage}</span>
                    </div>
                    <div className="homecard__info flex flex-col">
                        <span>AREA (SQFT)</span>
                        <span>{elevationSelected.size || currentHome.size}</span>
                    </div>
                </div>
                <div className="h-[75px] my-5 text-sm">
                    <p>{elevationSelected.description}</p>
                </div>
                <div className="mb-5">
                    <label htmlFor="select__floorplan">
                    SELECT FLOORPLAN
                    <select name="select__floorplan" id="select__floorplan"  className="lowrise__select mt-2" style={formatStyles(styles.borderSelectColor)}>
                        <option value="">SELECT FLOORPLAN</option>
                        {
                            currentHome?.homePlans && currentHome?.homePlans.map((hp, index)=> (
                                <option key={`floorplan_elevation_${index}`} value={hp.name}>{hp.name}</option>
                            ))
                        }
                    </select>
                    </label>
                </div>
                <div className="flex flex-row gap-3">
                    <button className="px-1 py-4 w-full text-[15px]" type="button" style={formatStyles(styles.requestInfo[1])}>Request Information</button>
                    <button className="border border-black px-1 py-4 w-full text-[15px]" type="button">Submit Worksheet</button>
                </div>
            </div>
            
            <div className="bg-black relative">
                <Splide 
                    aria-label="Elevations" 
                    options={{
                        perPage: 1, 
                        perMove: 1,
                        arrows: false,
                        pagination: false,
                        drag: false
                    
                    }}
                    className="h-full w-full"
                    hasTrack={ false }
                    ref={sliderRef}
                >
                    <SplideTrack className="h-full w-full">
                        {
                            currentHome.elevations.map(elevation => (
                                <SplideSlide className="w-full h-full" key={`elevation_image_${elevation.name}`}>
                                    <img 
                                        className={`object-cover w-full h-full transition-opacity ${loadImage ? 'opacity-100' : 'opacity-0 '}`}
                                        src={elevation?.image?.sizes['1536x1536']} 
                                        alt="home_design" 
                                        onLoad={()=>setLoadImage(true)}
                                    />
                                </SplideSlide>
                            ))
                        }
                    </SplideTrack>
                </Splide>
                
                <div className="flex flex-row gap-5 absolute bottom-3 left-5">
                    {
                        currentHome.elevations.map((e, index)=>(
                            <span 
                                key={`elevation__${e.name}`}
                                className={`bg-white size-10 flex items-center justify-center select-none cursor-pointer`} 
                                onClick={()=>controllerElevation(e, index)} style={(e.name === elevationSelected.name) ? formatStyles(styles.requestInfo[3]) : {} } 
                            >{e.name}</span>
                        ))
                    }
                </div>

                <div 
                    className="absolute bg-center bg-no-repeat bg-[length:50%] text-white bottom-3 right-3 cursor-pointer size-10" 
                    style={formatStyles(styles.fpPreview[1])}
                    onClick={()=>{setShowHome(false); setCurrentHome(null)}}
                ></div>
            </div>

        </div>
    )
}

export default ViewHome