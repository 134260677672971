import React from "react";
import { useParams } from "react-router-dom";
import slugify from "react-slugify";
import Slider from "../Templates/_Parts/Slide";
import MasonryGallery from "../Templates/_Parts/GalleryMasonry";
import SliderViews from "../Templates/_Parts/SliderViews";
import FullImageSize from '../Templates/_Parts/ImageFullScreen/index.js';
import HalfGallery from '../Templates/_Parts/HalfGallery/index.js';

const Subpage = ({ sectionContent, colors }) => {
  const { sectionSlug, subsectionSlug } = useParams();
  const section = sectionContent?.find(
    (section) => slugify(section.title) === sectionSlug
  );

  const subsection = section?.subsection?.find(
    (subitem) => slugify(subitem.titleSubsection) === subsectionSlug
  );

  if (!subsection) return <p>Subsection not found</p>;

  return (
    <>
      {subsection?.template === "floatSquare" && subsection?.floatSquare_item && (
        <Slider content={subsection} colors={colors} sugpage={sectionSlug} sectionContent={sectionContent} />
      )}

      {subsection?.template === "masonry" &&
        subsection?.images?.length > 0 && (
          <MasonryGallery images={subsection?.images} />
      )}

      {subsection?.template === "slideshow" && (
        <SliderViews images={ subsection?.images} />
      )}

      {subsection?.template === 'amenities' && (
       <FullImageSize url={subsection?.amenities?.amenitie?.amenitieImg}/>
      )}

      {subsection?.template === 'halfGallery' && (
       <HalfGallery section={subsection} sectionIndex={section?.index} subsectionIndex={subsection.indexSubsection} colors={colors} />
      )}
    </>
  );
};

export default Subpage;
