import { Splide } from '@splidejs/react-splide';
import HomePreviewCard from './HomePreviewCard';

const HomesPreviewCarousel = ({homesToShow, styles, favorites, toggleFavorite, setShowHome, setCurrentHome}) => {
  return (
    <Splide 
        aria-label="Homes preview" 
        options={{
            perPage: 4, 
            perMove: 4,
            pagination: false,
            gap: '1rem', 
            padding: { left: 40, right: 40 }, 
            classes: { prev : 'splide__arrow--prev lowrise__arrow__prev', next  : 'splide__arrow--next lowrise__arrow__next' }
        }}
    >
        {
            homesToShow.map(home => (
                <HomePreviewCard 
                    home={home} 
                    key={`homa_card_${home.id}`} 
                    styles={styles} 
                    favorites={favorites}
                    toggleFavorite={toggleFavorite}
                    setShowHome={setShowHome} 
                    setCurrentHome={setCurrentHome}
                />
            ))
        }
    </Splide>
  )
}

export default HomesPreviewCarousel