/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from "react";
import DetailsFloorplan from "./DetailsFloorplan";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import _ from "lodash";
import PanoramaView from "./PanoramaView";
import RequestInfoForm from "./RequestInfoForm";
import '../_styles/CompareFloorplanSlide.css';

const CompareFloorplanSlide = ({
  compareFloorplan,
  compareUnitRender,
  isFavorite,
  floorUnit,
  pointForUnit,
  styleProject,
  toggleFavorite,
  unitCompareFloorplan,
  contactEmail
}) => {

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [unitOri, setUnitOri] = useState("");

  const openModal = () => setShowRequestModal(true);
  const closeModal = () => setShowRequestModal(false);


  useEffect(() => {  }, [compareFloorplan, unitOri]);

  return (
    <div className="compared-floorplan-slide">
      
      <img
        src={compareFloorplan.fp_image_med[0]}
        alt={compareFloorplan.fp_name}
        className="full-size-image"
      />

      <div className="pb-5">
        <div className="grid grid-cols-4 gap-4 item-center container-data">
          <div className="py-4 flex-1 floorplate-img relative">
            {pointForUnit && pointForUnit.point && (
              <>
                <div
                  className="unit-float"
                  style={{
                    top: `${pointForUnit.point.point_position_y}%`,
                    left: `${pointForUnit.point.point_position_x}%`,
                    border: `none`,
                    background: `${
                      styleProject["noUi-connect"]["background-color"] !==
                      "#000000"
                        ? styleProject["noUi-connect"]["background-color"]
                        : styleProject.titleCardsColors["color"]
                    }`,
                  }}
                >
                  {compareUnitRender}
                </div>
                <img src={pointForUnit.floorplate_image} width="100%" />
              </>
            )}
          </div>

          <div className="info-floor col-span-2">
            <div
              className="fav-button flex items-center"
              onClick={(event) => {
                event.stopPropagation();
                toggleFavorite();
              }}
            >
              {!isFavorite ? (
                <HeartIconOutline
                  style={{
                    color: `${
                      styleProject["noUi-connect"]["background-color"] !==
                      "#000000"
                        ? styleProject["noUi-connect"]["background-color"]
                        : styleProject.titleCardsColors["color"]
                    }`,
                  }}
                />
              ) : (
                <HeartIconSolid
                  style={{
                    color: `${
                      styleProject["noUi-connect"]["background-color"] !==
                      "#000000"
                        ? styleProject["noUi-connect"]["background-color"]
                        : styleProject.titleCardsColors["color"]
                    }`,
                  }}
                />
              )}
              <h2 className="text-xl title-big-floorplan ml-1">
                {compareFloorplan.fp_name.toUpperCase()}
              </h2>
            </div>

            <div className="flex items-center">
              <small>UNIT </small>
              <div className="text-lg ml-1">{compareUnitRender}</div>
            </div>
            <DetailsFloorplan floorplan={compareFloorplan} floorUnit={floorUnit} unitOri={unitOri}/>
            <div className="mt-4 flex">
              <a
                className="btn btn-primary square-button"
                href={compareFloorplan.fp_pdf}
                target="_blank"
              >
                Download Floorplan
              </a>
              <button
                className="btn btn-primary square-button black ml-2"
                onClick={openModal}
              >
                Request Information
              </button>

  
            </div>
          </div>
        </div>
      </div>
      {showRequestModal && (
        <RequestInfoForm closeModal={closeModal} flooplan={compareFloorplan} contactEmail={contactEmail} unitFloor={unitCompareFloorplan}/>
      )}
    </div>
  );
};

export default CompareFloorplanSlide;
