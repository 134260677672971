import Sketchfab from "@sketchfab/viewer-api";

class SFHandler {
    api;
    animations;
    idFrame;
    currentObject;
    nodes;
    houses;
    hiddenLots = [];
    distinctives = {
        lots: [],
        houses: []
    };
    centerCamera = {
        desktop: {
            position: [-289.09426609594266, -244.77764142774905, 331.95304198125206],
            target: [-289.09426609594294, 9.812534418768475, -203.25871313415217]
        }, 
        mobile: {
            position: [-289.0942660959423, -332.2280052993269, 515.7954175857393],
            target: [-289.09426609594294, 9.812534418768498, -203.2587131341522]
        }
    }
    lots;
    #colors;
    #sceneuid;
    clickEvent =  (info) => {console.info(info)}    
    overlayer;

    constructor(sceneuid, {...rest}){
        this.idFrame = rest.idFrame ?? 'app-frame';
        this.overlayer = document?.getElementById("overlayer_lowrise") ?? null;
        this.#sceneuid = sceneuid;
        this.#clientInit(rest.options)        
        this.unavailableStatus = ['sold', 'onhold', 'reserved'];
        this.props = [];
        this.filtersUI = {}
        this.filters = {
            collections: [],
            status: [],
            area: {min: 0, max: Infinity},
            baths: {min: 0, max: Infinity},
            beds: {min: 0, max: Infinity},
            garage: {min: 0, max: Infinity},
            houses: [],
        }
        this.distinctives = (rest?.distinctives) ? {...this.distinctives, ...rest.distinctives } : this.distinctives;
        this.materials = { };
        this.centerCamera = (rest?.camera) ? {...this.centerCamera, ...rest.camera } : this.centerCamera;
        this.#colors =  {
            sold: {
                rgb: [0.4, 0.0, 0.0],
                opacity: 0.8,
            },
            move_in_ready: {
                rgb: [0.1411764705882353, 0.5215686274509804, 0.8980392156862745],
                opacity: 0.8,
            },
            disabled: {
                rgb: [0.8745098039215686, 0.8470588235294118, 0.7333333333333333],
                opacity: 0.8,
            }
        }
    }

    static checkDevice(){
        return (window.innerWidth <= 767) ? 'mobile' : 'desktop';
    }

    static convertHex(hex) {
        hex = hex.replace(/^#/, '');
        const r = parseInt(hex.slice(0, 2), 16) / 255;
        const g = parseInt(hex.slice(2, 4), 16) / 255;
        const b = parseInt(hex.slice(4, 6), 16) / 255;
        return [r, g, b]
    }

    #setNodes(nodes){
        this.nodes = nodes        
    }
    
    #setAPI(api){
        this.api = api
    }

    #setHouses(){
        this.houses = this.getNodeByName(this.distinctives.houses);
        this.houses.forEach(o => {
            if(!String(o?.name).includes('PARENT')){
                o.active = false;
                this.api.hide(o.instanceID);
            }
        })
    }

    #setLots(){
        this.lots = this.getNodeByName(this.distinctives.lots);
    }

    #clientInit(options){
        const iframe = document.getElementById(this.idFrame)
        const client = new Sketchfab("1.12.1", iframe);

        client.init(this.#sceneuid, {
            success: (api) => {
                let overlayer = this.overlayer
                if(overlayer){
                    overlayer.style.display = 'flex'
                }
                this.viewReady(api)
            },
            error: () => console.error("Sketchfab API error"),
            ...options
        });
    }

    async #setDefaultMaterials(){
        const defaultMaterials = {
            sold_default: {
                Opacity: {
                    enable: false,
                    factor: this.#colors.sold.opacity,
                    type: "alphaBlend"
                }
            },
            move_in_ready: {
                AlbedoPBR: {
                    color: this.#colors.move_in_ready.rgb
                },
                Opacity: {
                    enable: true,
                    factor: this.#colors.move_in_ready.opacity,
                    type: "alphaBlend"
                }
            },
            disabled: {
                AlbedoPBR: {
                    color: this.#colors.disabled.rgb
                },
                Opacity: {
                    enable: true,
                    factor: this.#colors.disabled.opacity,
                    type: "alphaBlend"
                }
            }
        };

        for(const [name, value] of Object.entries(defaultMaterials)){
            await this.addMaterial(name, value)
        }
    }
    
    #getLot(property){
        return this?.lots && this.lots.find(o => {
            let valueToCheck = property?.modelName.match(/\d+/g)?.join('')
            let lotName = o.children[0]?.name.match(/\d+/g)?.join('') ?? ''
            return lotName ===  valueToCheck
        });
    }

    #getHouses(property){
        return this?.houses && this.houses.find(o => {
            let valueToCheck = property?.modelName.match(/\d+/g)?.join('')
            let houseName = o.children[0]?.name.match(/\d+/g)?.join('') ?? ''
            return houseName ===  valueToCheck
        });
    }

    

    #paintMoveInReady(){
        let moveInReady = this.props.filter(h => ( h.type  === 'move_in_ready'));

        if(!moveInReady) return;

        moveInReady.forEach(async s => {
            s.visible = true

            let showLot = this.#getLot(s);
            
            if(showLot){
                let node = this.getNodeById(showLot.instanceID + 2);              
                this.api.assignMaterial(node, this.materials.move_in_ready, err => err && console.error(err))
            }

            let showHouse = this.#getHouses(s)

            if(showHouse){
                showHouse.visible = true;
                this.api.show(showHouse.instanceID);
                let lotIds  = showLot.children[0]?.name.match(/\d+/g)
                let houseToAnimation = (lotIds.length > 1) ? `${lotIds[0]}_${lotIds[1]}_` : `_${lotIds[0]}`;
                if(houseToAnimation){
                    this.startAnimation(houseToAnimation)
                }
            }
        })

    }

    async #paintSold(){
        
        let solds = this.props.filter(h => ( this.unavailableStatus.includes(h.status.toLowerCase()) ));
        let soldMaterial = await this.getMaterial('Wood_001_RED');

        this.materials['sold'] = (soldMaterial) ? soldMaterial?.id : this.materials?.sold_default;
        
        if(!solds) return;

        solds.forEach(async s => {
            s.visible = true
            let showHouse = this.#getHouses(s)
            let showLot = this.#getLot(s);       
            
            if(showLot){
                let node = this.getNodeById(showLot.instanceID + 2);              
                this.api && this.api.assignMaterial(node, this.materials['sold'], err => err && console.error(err))
            }

            if(showHouse){
                showHouse.visible = true;
                let node = this.getNodeById(showHouse.instanceID + 3);
                this.api.show(showHouse.instanceID);
                let lotIds  = showLot.children[0]?.name.match(/\d+/g)
                let houseToAnimation = (lotIds.length > 1) ? `${lotIds[0]}_${lotIds[1]}_` : `_${lotIds[0]}`;
                if(houseToAnimation){
                    this.startAnimation(houseToAnimation)
                }
                this.api && this.api.assignMaterial(node, this.materials['sold'], err => err && console.error(err));
            }
        })
    }

    async addMaterial(materialName, options = {}){
        const materialCreated = await this.createMaterial(options)
        if(materialCreated){
            this.materials[materialName] = materialCreated.id
        }
    }

    setProps(props){
        this.props = props
        this.resetFilters()
    }

    setColors(newColors){
        for (const [name, attrs] of Object.entries(newColors)) {
            this.#colors[name] = attrs   
        }
    }

    setAnimations(animations){
        this.animations = animations
    }

    setFiltersUIItems(key, values){
        this.filtersUI[key] = values
    }

    getNodeById(instanceID){
        return this?.nodes[instanceID] ?? [];
    }

    createMaterial(options = {}){
        return new Promise((res, rej) => {
            this.api.createMaterial({
              channels: {
                AlbedoPBR: {
                    color: this.#colors.sold.rgb
                },
                Opacity: {
                  enable: true,
                  factor: this.#colors.sold.opacity,
                  type: "alphaBlend"
                },
                SpecularPBR: {
                  enable: true
                },
                cullFace: "BACK",
                ...options
              }
            }, (err, material) => {
                if(err){ return rej(err) }

                res(material)
            })
        })
    }

    setClickEvent = (event) => {
       this.clickEvent = event
    }
    
    setCameraPosition = () => {
        if(this?.currentObject){
            const [x,y,z] = this.currentObject.position3D
            
            this.api.setCameraLookAt([x-20, y-110, z+160], [x, y+20, z-120], 1, err => err && console.error(err));
        }
    }
    
    recenterCameraPosition = () => {
        const DEVICE = SFHandler.checkDevice()
        this?.api && this.api.setCameraLookAt(this.centerCamera[DEVICE]?.position, this.centerCamera[DEVICE]?.target, 1, err => err && console.error(err));
    }
            
    startAnimation(house){
        if(!this.animations) return;
        
        const animationFound = this.animations.findIndex(e => e[1].includes(house))
        this.api.setCurrentAnimationByUID(this.animations[animationFound][0], (err) => {
            if (err) { 
                console.error(err); 
                return; 
            }
            this.api.play(err => err && console.error(err))
            
        });
    }

    getAnimationsList(){
        if(this.api){
            return new Promise((res, rej) => {
                this.api.getAnimations((err, animations) => {
                    if (err) { return rej(err) }
                    res(animations)
                })
            })
        }
    }

    getMaterial(nameMaterial){
        if(this.api){
            return new Promise((res, rej) => {
                this.api.getMaterialList((err, materials) => {
                    if (err) { return rej(err) }
                    let material = materials.find(e => e.name === nameMaterial)
                    res(material)
                })
            })
        }
    }

    getNodeByName(nodename){
        return Object.values(this.nodes).filter((node) => node.type === "MatrixTransform" && nodename.some(v => node.name.trim().toLowerCase().includes(v.trim().toLowerCase())));
    }

    getNodes(){
        if(this.api){
            return new Promise((res, rej) => {
                this.api.getNodeMap((err, nodes) => {
                    if (err) { return rej(err) }
                    res(nodes)
                })
            })
        }
    }

    updateFilter(keyword, value){
        this.filters[keyword] = value;
    }

    filterLots(){
        let dataFilteredToShow = this?.props.filter(
            p => 
                this.filters.collections.includes(p.collection) &&
                this.filters.types.includes(p.type) 
                // && (
                // p?.homesAvailable.some(e => !e) || (
                //     p?.homesAvailable.some(h => parseInt(h.size) >= parseInt(this.filters.area.min) && parseInt(h.size) <= parseInt(this.filters.area.max))  &&
                //     p?.homesAvailable.some(h => parseInt(h.bed) >= parseInt(this.filters.beds.min) && parseInt(h.bed) <= parseInt(this.filters.beds.max)) &&
                //     p?.homesAvailable.some(h => parseInt(h.bath) >= parseInt(this.filters.baths.min) && parseInt(h.bath) <= parseInt(this.filters.baths.max)) &&
                //     p?.homesAvailable.some(h => parseInt(h.garage) >= parseInt(this.filters.garage.min) && parseInt(h.garage) <= parseInt(this.filters.garage.max)) 
                // ))
        )
        
        dataFilteredToShow.forEach(s => {
            let showLot = this.#getLot(s);      
            
            if(showLot){
                let node = this.getNodeById(showLot.instanceID + 2);
                let material = (this.materials[s.type]) ? this.materials[s.type] : node?.materialID;
                this.api.assignMaterial(node, material, err => err && console.error(err))
            }

            if(s.visible){
                let showHouse = this.#getHouses(s)

                if(showHouse && !showHouse.active){
                    showHouse.active = true;
                    this.api.show(showHouse.instanceID);
                    // let houseToAnimation = (s?.blockNumber && s.blockNumber !== 0) ? `H_B${Number(s.unitNumber)}_${String(s.blockNumber).padStart(3, "0")}_Hs` : `H_${String(s.unitNumber).padStart(3, "0")}_Hs`;
                    // if(houseToAnimation){
                    //     this.startAnimation(houseToAnimation)
                    // }
                }
            }
        })

        let dataFilteredToHide = this?.props.filter(p => !dataFilteredToShow.includes(p))
        this.hiddenLots = dataFilteredToHide
        dataFilteredToHide.forEach(s => {
        
            let hideLot = this.#getLot(s);         
            
            if(hideLot){
                let node = this.getNodeById(hideLot.instanceID + 2);
                this.api.assignMaterial(node, this.materials.disabled, err => err && console.error(err))
            }

            let hideHouse = this.#getHouses(s)

            if(hideHouse){
                hideHouse.active = false;
                this.api.hide(hideHouse.instanceID);
            }
        })

        
        this?.api && this.api.seekTo(0, err => err && console.log(err));
        this?.api && this.recenterCameraPosition()
    }

    filterHouses(){
        this.hideAll()
        let dataFilteredToShow = this.props.filter(h => h.homesAvailable.some(p => this.filters.houses.includes(Number(p?.id))))

        dataFilteredToShow.forEach(s => {
            let showLot = this.#getLot(s);       
            
            if(showLot){
                let node = this.getNodeById(showLot.instanceID + 2);
                let material = (this.materials[s.type]) ? this.materials[s.type] : node?.materialID;
                this.api.assignMaterial(node, material, err => err && console.error(err))
            }

            if(s.visible){
                let showHouse = this.#getHouses(s)
                
                if(showHouse && !showHouse.active){
                    this.api.show(showHouse.instanceID);
                }
            }
        })

        this?.api && this.api.seekTo(0, err => err && console.log(err));
        this?.api && this.recenterCameraPosition()
    }

    resetFilters(){
        // Add collections
        this.setFiltersUIItems('collections', Array.from(new Set(this.props.map(e => e.collection))))
        this.updateFilter('collections', this?.filtersUI?.collections)

        // Add status
        this.setFiltersUIItems('status', Array.from(new Set(this.props.map(e => e.status))))
        this.updateFilter('status', this?.filtersUI?.status)

        // Add types
        this.setFiltersUIItems('types', Array.from(new Set(this.props.map(e => e.type))))
        this.updateFilter('types', this?.filtersUI?.types)
        
        // Add houses
        this.setFiltersUIItems('houses', Array.from(new Set(this.props.flatMap(e => e.homesAvailable).filter(Boolean).map(e => e.id))))
        this.updateFilter('houses', this?.filtersUI?.houses)

        // Add prices
        this.setFiltersUIItems('prices', Array.from(new Set(this.props.flatMap(e => e.homesAvailable).filter(Boolean).map(e => e.price))))      

        // Add baths
        this.setFiltersUIItems('baths', Array.from(new Set(this.props.flatMap(e => e.homesAvailable).filter(Boolean).map(e => e.bath))))
        this.updateFilter('baths', {min: Math.min(...this.filtersUI.baths), max: Math.max(...this.filtersUI.baths)})
        
        // Add beds
        this.setFiltersUIItems('beds', Array.from(new Set(this.props.flatMap(e => e.homesAvailable).filter(Boolean).map(e => e.bed))))
        this.updateFilter('beds', {min: Math.min(...this.filtersUI.beds), max: Math.max(...this.filtersUI.beds)})

        // Add garages
        this.setFiltersUIItems('garage', Array.from(new Set(this.props.flatMap(e => e.homesAvailable).filter(Boolean).map(e => e.garage))))
        this.updateFilter('garage', {min: Math.min(...this.filtersUI.garage), max: Math.max(...this.filtersUI.garage)})

        // Add area
        this.setFiltersUIItems('area', Array.from(new Set(this.props.flatMap(e => e.homesAvailable).filter(Boolean).map(e => e.size))))
        this.updateFilter('area', {min: Math.min(...this.filtersUI.area), max: Math.max(...this.filtersUI.area)})
    }

    hideAll(){
        if(this.nodes && this.api){
            this.props.forEach(s => {
                let hideLot = this.#getLot(s);       
                
                if(hideLot){
                    let node = this.getNodeById(hideLot.instanceID + 2);
                    this.api.assignMaterial(node, this.materials.disabled, err => err && console.error(err))
                }

                let hideHouse = this.#getHouses(s)

                if(hideHouse){
                    hideHouse.active = false
                    this.api.hide(hideHouse.instanceID);
                }
            })
            this.api.seekTo(0, err => err && console.log(err));
        }
    }

    viewReady(api){
        api.addEventListener("viewerready", async ()=>{
            try {
                this.#setAPI(api)
                api.pause((err) => { err && console.error(err) })
                api.setCycleMode('one', err => err && console.error(err))
                if(this.overlayer){
                    this.overlayer.style.display = 'none'
                }
                this.setAnimations(await this.getAnimationsList())
                this.#setNodes(await this.getNodes())
                await this.#setDefaultMaterials()
                this.#setHouses()
                this.#setLots()
                this.#paintMoveInReady()
                await this.#paintSold()
                
                this.addClickEvent()
            } catch (error) {
                console.error(error)
            }
        })
    }

    addClickEvent(){
        if(this?.api){
            this.api.addEventListener('click', (info)=>{
                this.currentObject = info
                const lot = this.getNodeById(info.instanceID - 1)
                this.houses.forEach(o => {
                    if (o.active && !o.visible && !String(o?.name).includes('PARENT')) {
                        o.active = false;
                        this.api.hide(o.instanceID);
                    }
                })

                
                

                //Check if the lot name exists in the lots array and if it has a name property.
                if (lot?.name && Object.values(this.distinctives).flat().some(e => lot.name.includes(e.trim()))) {
                    const lotIds  = lot.name.match(/\d+/g)
                    // const lotName = this.getNodeById(lot.instanceID - 1)?.name;
                    const propSelected = this?.props.find(o => {
                        let valueToCheck = String(lot?.name).match(/\d+/g)?.join('')
                        let lotName = o.modelName.match(/\d+/g)?.join('') ?? ''
                        return lotName ===  valueToCheck
                    });

                    const checkPropSelected =  this.hiddenLots.some(o => o.modelName === propSelected.modelName)

                    if(checkPropSelected) return;

                    const houseToAnimation = (lotIds.length > 1) ? `${lotIds[0]}_${lotIds[1]}_` : `_${lotIds[0]}`;                    
                    const showHouse = this.houses.find(o => o.name.toLowerCase().includes(houseToAnimation.toLocaleLowerCase()) && !o.name.toLowerCase().includes('parent'))
                
                    if(showHouse){
                        // if(!showHouse.active){
                        //     this.startAnimation(houseToAnimation)
                        // }
                        this.setCameraPosition(info.position3D)
                        if(!this.unavailableStatus.includes(propSelected?.status)){
                            this.startAnimation(houseToAnimation)
                            showHouse.active = true;
                            setTimeout(() => {
                                this?.api && this.api.show(showHouse.instanceID);

                            }, 100);
                        }
                    }
                    this.clickEvent(propSelected)
                }else{
                    this.recenterCameraPosition()
                }
            }, { pick: "fast" })
        }
    }
}

export default SFHandler