/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect } from 'react';
import './FullImageSize.css';

const FullImageSize = ({url}) => {

    
    return (
        <div className='image-container'>
            <img src={url} />
        </div>
    );

}

export default FullImageSize;