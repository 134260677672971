// import { usePageStore } from "../../../stores"
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide'
import { useEffect, useRef, useState } from "react";
import { formatStyles } from "../../../../plugins/FormatStyle";
import '@splidejs/react-splide/css';

const HalfGallery = ({section, sectionIndex, subsectionIndex, colors}) => {
  const [checkSubsection, setCheckSubsection] = useState(false)
  const styles  = colors ?? null
  
  //Slider refs
  const descriptionRef  = useRef();
  const imageRef        = useRef();

  //Slider navigation
  const handlerNavigation = (type) => {
    const descriptionsSlider = descriptionRef.current.splide;

    if(type === 'prev'){
      descriptionsSlider.go('<')
    }else{
      descriptionsSlider.go('>')
    }
  }

  useEffect(() => {
    if(section){

      //Add slider events
      const descriptionsSlider = descriptionRef.current?.splide;
      const imageSlider = imageRef.current?.splide;

      if(descriptionsSlider && imageSlider){
        descriptionsSlider.on('move', (newIndex) => {
          imageSlider.go(newIndex);
        });
  
        descriptionsSlider.on('drag', () => {
          const activeIndex = descriptionsSlider.index;
          imageSlider.go(activeIndex);
        });
      }
      setCheckSubsection(true)
    }
    
  }, [section]);

  return (
    <>
    {
      checkSubsection ? (
        <main className="w-full h-full flex items-center justify-center" >
              
              <section className="h-full w-full md:h-[80%] md:w-[80%] border grid md:grid-cols-2 grid-rows-2 md:grid-rows-1">
                <Splide 
                  hasTrack={false}
                  id="half__description"
                  aria-label="description_content" 
                  className="bg-white flex items-center justify-center w-[100dvw] md:w-auto relative" 
                  options={{
                    pagination: false, 
                    arrows: false, 
                    type: "loop",
                    drag: (section?.descriptions && section?.descriptions?.length > 1)
                  }} 
                  ref={descriptionRef}>
                    
                    {
                      // Arrows
                      section?.descriptions && section?.descriptions?.length > 1 && (
                        <div 
                          id="arrows_dots" 
                          className="absolute z-50 flex gap-3 bottom-5 right-7 text-3xl"
                        >
                          <span 
                            onClick={()=>handlerNavigation('prev')} 
                            className="hover:scale-125 cursor-pointer transition-all ease-linear bg-no-repeat bg-cover size-6"
                            style={formatStyles(styles?.arrowLeft[section?.indexSubsection])}
                          ></span>
                          <span 
                            onClick={()=>handlerNavigation('next')} 
                            className="hover:scale-125 cursor-pointer transition-all ease-in-out bg-no-repeat bg-cover size-6"
                            style={formatStyles(styles?.arrowRight[section?.indexSubsection])}
                            ></span>
                        </div>
                      )
                    }
                   

                    <SplideTrack className="max-w-[60%]">
                      {
                        section?.descriptions && section?.descriptions.map((descriptionObject, index) => (
                          <SplideSlide key={`description_${index}`} className="flex flex-col gap-3">
                            <h2 
                              className="text-5xl font-bold text-balance mb-5"
                              style={formatStyles(styles[`titleColor_${sectionIndex}_${subsectionIndex}`])}
                            >{descriptionObject?.description?.description_title}</h2>
                            <p className="subtitle">{descriptionObject?.description?.description_subtitle}</p>
                            <p 
                              className="text-balance whitespace-pre-line"
                              style={formatStyles(styles[`textColor_${sectionIndex}_${subsectionIndex}`])}
                            >
                              {descriptionObject?.description?.description_texts}
                            </p>
                            <a href={`./${descriptionObject?.description?.description_cta?.slug}`} className="border-black border-b-[3px] w-max">{descriptionObject?.description?.description_cta?.value}</a>
                          </SplideSlide>
                        ))
                      }                     
                    </SplideTrack>
                  </Splide>

                
                <Splide  
                  hasTrack={false} 
                  className="row-start-1 md:row-start-auto"  
                  aria-label="images_content" 
                  options={{
                    pagination: false, 
                    arrows: false, 
                    type: "fade", 
                    rewind: true, 
                    drag: false, 
                    speed: 700
                  }} 
                  ref={imageRef}>
                  <SplideTrack className="h-full">
                    {
                      section?.halfimages?.image && section?.halfimages?.image.map((imageObject, index) => (
                        <SplideSlide key={`image_${index}`}>
                          <figure className="h-full">
                            <img className="object-cover h-full w-[100dvw]" src={imageObject?.value} alt="" />
                          </figure>
                        </SplideSlide>
                      ))
                    }
                  </SplideTrack>
                </Splide>

              </section>
          </main>
      ) : (
        <h3>Loading data...</h3>
      )
    }
      
    </>
  )
}

export default HalfGallery