/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { TrashIcon } from '@heroicons/react/24/outline'
import '../_styles/WorkSheetList.css';

const WorkSheetList = ({ worksheets, setWorksheetsList, toggleWorksheets }) => {
    return (
        <div className="list-worksheets absolute top-full left-0 w-64 mt-2 p-4 bg-white shadow-lg border rounded pt-5">
                <div className="close-btn" onClick={() => setWorksheetsList(false)}>&times;</div>
              <ul>
                {worksheets.map((floorplan, index) => (
                  <li key={index} className="p-2 border-b last:border-none flex items-center item-worksheet-list">
                    <div className="ml-2 floorplan-thumbnail">
                        <img src={floorplan.fp_image_low}  /> 
                    </div>
                    <div className="info-title">
                        <h3>{floorplan.fp_name.toUpperCase()}</h3>
                        <h4>UNIT {floorplan.unit}</h4>
                        <p>BEDS {floorplan.fp_bedrooms} | BATHS {floorplan.fp_bathrooms}</p>
                    </div>
                   <div className="remove-worksheet" onClick={() => toggleWorksheets(floorplan, false)}>
                    <TrashIcon className="size-6"/>
                   </div>
                  </li>
                ))}
              </ul>
              <button className="btn btn-primary mt-2" onClick={() => setWorksheetsList(false)}>Submit Worksheet</button>
            </div>
    )
};

export default WorkSheetList;