/* eslint-disable array-callback-return */
import React from 'react';

const DynamicFontComponent = ({font}) => {

  font.map((f) => {
    if(f){
      Object.keys(f).some((prop) => {
        if(~prop.indexOf('url_'))
          f['url'] = f[prop];
      })
    }
  })

  return (
      <style>
        { font && font.map((f,i) => 
          `
          @font-face {
            font-family: '${f.name}';
            src: url('/fonts/${f.url}');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
          }
        `
        )}
      </style>
  );
};

export default DynamicFontComponent;
