/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
//import { getStyleObjectFromString } from '../../../plugins/FormatStyle.js';
import './logo.css';

const Logo = ({img}) => {
    return (
        <div id="logo">
            <img id="logoURL" src={img} />
        </div>
    )}

 

  
export default Logo;