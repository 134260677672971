/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useParams } from "react-router-dom";
import config from "../../../config";
import { saveOrUpdateProject, getProjects, getProjectById } from "../../../db";
import Loader from "../_Parts/Loader";
// import { isMobile } from "react-device-detect";
import DualRangeSlider from "../../Templates/_Parts/DualRange";
import _styles from "./_styles/style.css"
import { formatStyles } from "../../../plugins/FormatStyle";
import newSFIntsance from "../../../plugins/MastersketchLowrise";
import HomePreviewCard from "./_parts/HomePreviewCard";
import HomesPreviewCarousel from "./_parts/HomesPreviewCarousel.js";
import BtnFavourite from "./_parts/BtnFavourite";
import ViewHome from "./_parts/ViewHome";

const SiteplanLowrise = ({styles}) => {

   const { projectId } = useParams();
   
   //Phase states
   const [collections, setCollections] = useState([])
   const [phases, setPhases] = useState([])
   const [currentPhase, setCurrentPhase] = useState('phase_1')
   const [homesToShow, setHomesToShow] = useState([])
   const [showPreview, setShowPreview] = useState(false)
   const [showHome, setShowHome] = useState(false)
   const [currentHome, setCurrentHome] = useState(null)

   //Favs
   const [favorites, setFavs] = useState([])

   const sliderRef = useRef();
   const [min, setMin] = useState(0)
   const [max, setMax] = useState(20)
   const [loading, setLoading] = useState(false)
   const [instanceSF, setInstanceSF] = useState(false)
   const [showCollections, setShowCollections] = useState(true)
   const [showFilters, setShowFilters] = useState(true)
   const [showMoreFilters, setShowMoreFilters] = useState(true)

  const handleRannge = (values)=>{
    console.log(values)
  }

  useEffect(() => {
   const loadStoredProjects = async () => {
     setLoading(true);
     try {
       const storedProjects = await getProjects();
       const currentProject = storedProjects.find(project => project.id === projectId);

       if (currentProject) {
         setPhases(Object.values(currentProject.data?.phases))
         const instance = await newSFIntsance(projectId, currentProject.data?.phases[currentPhase], currentProject?.sketch_style)
         setInstanceSF(instance)
         setCollections(Object.values(currentProject.data?.phases[currentPhase]?.collections))
         instance.setClickEvent((lot) => {
            if (lot?.homesAvailable) {
               setHomesToShow(lot?.homesAvailable)
               setShowPreview(true)
            }
         })
         
         if(currentProject?.favorites){
            setFavs(currentProject?.favorites)
         }

      }
     } catch (error) {
       console.error("Error loading stored projects:", error);
     } finally {
       setLoading(false);
       console.log(styles)
     }
   };

   
   if (projectId) {
     loadStoredProjects();
   }
 }, [projectId, currentPhase]);

    
   //homes functions
   function handleHome(homes){
      setHomesToShow(homes)
   }


   const toggleFavorite = async (home_id) => {
      setFavs((prevFavs) => {
          const updatedFavs = prevFavs.includes(home_id)
          ? prevFavs.filter((fav) => fav !== home_id)
          : [...prevFavs, home_id];

          saveOrUpdateProject({
          id: projectId,
          favorites: updatedFavs,
          });

          return updatedFavs;
      });
  };

   function updateFilters(){
      let collectionsFilter = [...document.querySelectorAll('.lowrise__size__filter input:checked')].map(e => e.value)
      instanceSF.updateFilter('collections', collectionsFilter)
      
      let availabilityFilter = [...document.querySelectorAll('.lowrise__availability__filter input:checked')].map(e => e.value)
      instanceSF.updateFilter('types', availabilityFilter)

      instanceSF.filterLots()
   }

  return (
      <main className="w-full h-[100dvh] grid grid-cols-[75%,25%] relative" id="siteplan">
         {showHome && (
            <ViewHome 
               styles={styles} 
               setShowHome={setShowHome} 
               setCurrentHome={setCurrentHome} 
               favorites={favorites} 
               toggleFavorite={toggleFavorite} 
               currentHome={currentHome} 
            />
         )}

          <div className="lowrise__siteplan relative">
            <div className="w-full h-full relative z-10">
               <iframe src="" title="app_lowrise" id="app-frame" className="w-full h-full"></iframe>
            </div>
            {
               showPreview && (
                  <div className="absolute z-20 bottom-0 left-0 w-full overflow-x-autoflex flex-row min-h-52 pt-10 bg-white">
                     <div className="absolute top-2 right-4 font-[cursive] cursor-pointer" onClick={()=>{instanceSF?.recenterCameraPosition(); setShowPreview(false);}}>X</div>
                     {
                        homesToShow.length > 0 && (
                           <HomesPreviewCarousel 
                              homesToShow={homesToShow} 
                              styles={styles} 
                              favorites={favorites}
                              toggleFavorite={toggleFavorite}
                              setCurrentHome={setCurrentHome}
                              setShowHome={setShowHome}
                           />
                        )
                     }
                  </div>
               )
            }
          </div>
          <div className="lowrise__filters grid relative max-h-[100dvh]  grid-rows-[1fr_auto]" style={formatStyles(styles.search[1])} >
            <div className="lowrise__filters__list overflow-y-auto md:pt-28 2xl:pt-40 mb-14 flex flex-col">
               <div className="lowrise__filters__container lowrise__filters__checkboxes px-10 lowrise__availability__filter pb-6" >
                  <h4 style={formatStyles(styles['title'])} >AVAILABILITY</h4>
                  <ul className="grid grid-cols-2 pt-7 gap-3">
                     <li>
                        <label htmlFor="newBuild" className="flex gap-2">
                           <input type="checkbox" onChange={updateFilters} id="newBuild" defaultChecked={true} name="newBuild" className="lowrise__filters__checkboxes__item"  style={formatStyles(styles?.newBuildCheckColor)} value="presale" />
                           New build
                        </label>
                     </li>
                     <li>
                        <label htmlFor="move_in_ready">
                           <input type="checkbox" onChange={updateFilters} id="move_in_ready" defaultChecked={true} name="move_in_ready" className="lowrise__filters__checkboxes__item" value="move_in_ready"  style={formatStyles(styles?.moveindCheckColor)}  />
                           Move in ready
                        </label>
                     </li>
                     <li>
                        <label htmlFor="lw_sold">
                           <input type="checkbox" onChange={updateFilters} id="lw_sold" defaultChecked={true} name="lw_sold" value="sold" className="lowrise__filters__checkboxes__item"  style={formatStyles(styles?.soldCheckColor)} />
                           Sold
                        </label>
                     </li>
                  </ul>
                  {
                     phases.length > 1 && (
                        <div className="mt-5">
                           <span className="my-3 block">
                              PHASE
                           </span>
                           <select name="" id="" onChange={(e)=>{setCurrentPhase(e.target.value)}} className="lowrise__select" style={formatStyles(styles.borderSelectColor)}>
                              {
                                 phases && phases.map((phase, index)=>(
                                    <option key={`phase_${index+1}`} value={`phase_${index+1}`} >PHASE {index+1}</option>
                                 ))
                              }
                           </select>
                        </div>
                     )
                  }
               </div>
               <div className="lowrise__filters__container lowrise__filters__checkboxes lowrise__filters__accordeon group lowrise__size__filter">
                  <h4 className="py-2 group-has-[:checked]:border-b border-t border-y-black flex flex-row justify-between">
                     <label htmlFor="accordeon__collections" className="flex w-full justify-between px-10 select-none" style={formatStyles(styles['title'])}>
                        COLLECTIONS
                        <img height="20" width="20" src="/mkt/arrow-up.png" alt="arrow-control" className="hidden group-has-[:checked]:block" />
                        <img height="20" width="20" src="/mkt/arrow-down.png" alt="arrow-control" className="group-has-[:checked]:hidden" />
                     </label>
                     <input type="checkbox" id="accordeon__collections" defaultChecked={true} onChange={()=>setShowCollections(!showCollections)} name="accordeon__collections" className="hidden" />
                  </h4>
                  {
                     showCollections && (
                        <ul className="grid-cols-2 hidden px-10 group-has-[:checked]:grid py-7 gap-2 ">
                           {
                              collections && collections?.map((collection, index) =>(
                                 <li key={`collection_${index}`} className="select-none">
                                    <label htmlFor={`collection_${collection.collection_id}`}>
                                       <input type="checkbox" id={`collection_${collection.collection_id}`} defaultChecked={true} onChange={updateFilters} value={collection.collection_name} name={`collection_${collection.collection_id}`} className="lowrise__filters__checkboxes__item" style={{backgroundColor: collection?.collection_color}} />
                                       <span>{collection.collection_name}</span>
                                    </label>
                                 </li>
                                 )
                              )
                           }
                          
                        </ul>
                     )
                  }
               </div>
               <div className="lowrise__filters__container lowrise__filters__accordeon group">
                  <h4 className="py-2 px-10 group-has-[:checked]:border-b border-t border-y-black flex flex-row justify-between">
                     <label htmlFor="accordeon__filters" className="flex w-full justify-between select-none" style={formatStyles(styles['title'])}>
                        FILTERS 
                        <img height="20" width="20" src="/mkt/arrow-up.png" alt="arrow-control" className="hidden group-has-[:checked]:block" />
                        <img height="20" width="20" src="/mkt/arrow-down.png" alt="arrow-control" className="group-has-[:checked]:hidden" />
                     </label>
                     <input type="checkbox" id="accordeon__filters" defaultChecked={true} onChange={()=>setShowFilters(!showFilters)} name="accordeon__filters" className="hidden" />
                  </h4>
                  {
                     showFilters && (
                        <ul className="grid-cols-1 px-10 grid py-7 gap-3">
                           <li>
                              <span className="mb-5 block">
                                 AREA (SQ. FT)
                              </span>
                              <DualRangeSlider
                                 ref={sliderRef}
                                 min={min}
                                 max={max}
                                 onMouseUp={({ min, max }) => {
                                    handleRannge({ min, max });
                                 }}
                              />
                           </li>
                           <li>
                              <span className="mb-5 block">
                                 BEDROOMS
                              </span>
                              <DualRangeSlider
                                 ref={sliderRef}
                                 min={min}
                                 max={max}
                                 onMouseUp={({ min, max }) => {
                                    handleRannge({ min, max });
                                 }}
                              />
                           </li>
                        </ul>
                     )
                  }
               </div>
               <div className="lowrise__filters__container lowrise__filters__accordeon group">
                  <h4 className="py-2 border-y border-y-black flex flex-row justify-between px-10">
                     <label htmlFor="accordeon__morefilters" className="flex w-full justify-between cursor-pointer select-none" style={formatStyles(styles['title'])}>
                        MORE FILTERS
                        <img height="20" width="20" src="/mkt/arrow-up.png" alt="arrow-control" className="hidden group-has-[:checked]:block" />
                        <img height="20" width="20" src="/mkt/arrow-down.png" alt="arrow-control" className="group-has-[:checked]:hidden" />
                     </label>
                     <input type="checkbox" id="accordeon__morefilters" defaultChecked={true} onChange={()=>setShowMoreFilters(!showMoreFilters)} name="accordeon__morefilters" className="hidden" />
                  </h4>
                  {
                     showMoreFilters && (
                        <ul className="grid-cols-1 grid py-7 gap-3 px-10">
                           <li>
                              <span className="mb-5 block">
                                 BATHROOM
                              </span>
                              <DualRangeSlider
                                 ref={sliderRef}
                                 min={min}
                                 max={max}
                                 onMouseUp={({ min, max }) => {
                                    handleRannge({ min, max });
                                 }}
                              />
                           </li>
                           <li>
                              <span className="mb-5 block">
                                 PARKING
                              </span>
                              <DualRangeSlider
                                 ref={sliderRef}
                                 min={min}
                                 max={max}
                                 onMouseUp={({ min, max }) => {
                                    handleRannge({ min, max });
                                 }}
                              />
                           </li>
                           <li>
                              <span className="mb-5 block">
                                 BY NAME
                              </span>
                              <select name="" id="" className="lowrise__select" style={formatStyles(styles.borderSelectColor)}>
                                 <option value="">SELECT HOME</option>
                                 <option value="">SPRINGBOOK</option>
                              </select>
                           </li>
                        </ul>
                     )
                  }
               </div>
               
            </div>
            <button type="button" className="py-5 px-10 absolute flex justify-center items-center bottom-0 right-0 w-full bg-red-950 z-40" style={formatStyles(styles.gbtnColorLow)} >
               <span className="border-b border-b-white">RESET</span>
            </button>
          </div>
      </main>
  );
};

export default SiteplanLowrise;
