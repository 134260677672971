import React, { useState, useRef, useEffect } from "react";
import '../_styles/PanoramaView.css';

const PanoramaView = ({ data, onClosePanorama }) => {
  const {
    view_image,
    view_thumbnail,
    view_reference_point,
    view_total_degrees,
  } = data;

  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const [compass, setCompass] = useState(parseInt(view_reference_point) || 0);
  const [focusPosition, setFocusPosition] = useState(0);
  const containerRef = useRef(null);

  const FOCUS_WIDTH_PERCENT = 20; 
  const VIEW_IMAGE_WIDTH = 5000; 


  /**
   * Handles the drag start event.
   *
   * @param {Event} e - The drag start event.
   */
  const handleDragStart = (e) => {
    e.preventDefault(); 
    setDragging(true);
    handleThumbnailDrag(e);
  };

  /**
   * Handles the end of a drag event by setting the dragging state to false.
   */
  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDragMove = (e) => {
    if (dragging) {
      handleThumbnailDrag(e);
    }
  };

  /**
   * Handles the dragging of the thumbnail to update the position, compass, and focus position.
   *
   * @param {MouseEvent | TouchEvent} e - The event object from the drag action.
   */
  const handleThumbnailDrag = (e) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const thumbnailWidth = containerRef.current.offsetWidth;

      let mouseX;

      if (e.type.includes('touch')) {
        mouseX = e.touches[0].clientX - containerRect.left;
      } else {
        mouseX = e.clientX - containerRect.left;
      }

      mouseX = Math.max(0, Math.min(mouseX, thumbnailWidth));

      const clickPercentage = (mouseX / thumbnailWidth) * 100;
      const newPosition = (clickPercentage / 100) * VIEW_IMAGE_WIDTH;
      setPosition(-newPosition);

      const referencePoint = parseInt(view_reference_point) || 0;
      const newCompassValue = (clickPercentage / 100) * parseInt(view_total_degrees) + referencePoint;
      setCompass(newCompassValue % 360);

      const maxFocusPosition = 100 - FOCUS_WIDTH_PERCENT;
      setFocusPosition(Math.min(clickPercentage, maxFocusPosition));
    }
  };


  useEffect(() => {
    if (dragging) {
      window.addEventListener("mousemove", handleDragMove);
      window.addEventListener("mouseup", handleDragEnd);
      window.addEventListener("touchmove", handleDragMove);
      window.addEventListener("touchend", handleDragEnd);
    } else {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("touchmove", handleDragMove);
      window.removeEventListener("touchend", handleDragEnd);
    }
    return () => {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("touchmove", handleDragMove);
      window.removeEventListener("touchend", handleDragEnd);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragging]);

  return (
    <div className="panorama-overlay">
      <button className="close-button-panorama" onClick={onClosePanorama}>X</button>
      <div className="panorama-container">
        <div
          className="panorama-image"
          style={{
            backgroundImage: `url(${view_image})`,
            backgroundPositionX: `${position}px`, 
            transition: dragging ? "none" : "background-position 0.3s ease", 
          }}
        />
        <div
          className="thumbnail-container"
          ref={containerRef}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          style={{
            backgroundImage: `url(${view_thumbnail})`,
          }}
        >
          <div
            className="thumbnail-focus"
            style={{
              left: `${focusPosition}%`,
            }}
          />
        </div>
      </div>

      <div className="compass-container">
        <div className="compass" style={{ transform: `rotate(${compass}deg)` }}>
          <div className="compass-arrow north"><span>N</span></div>
          <div className="compass-arrow east"><span>E</span></div>
          <div className="compass-arrow south"><span>S</span></div>
          <div className="compass-arrow west"><span>W</span></div>
        </div>
      </div>
    </div>
  );
};

export default PanoramaView;
