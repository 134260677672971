import React from "react";
import DynamicFontComponent from '../plugins/Font.js';

const Header = ({fonts}) => {
    return (
        <>
         <DynamicFontComponent font={fonts} />
        </>
    );
}

export default Header;