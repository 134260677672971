import React from "react";
import DetailsFloorplan from "./DetailsFloorplan";
import { HeartIcon as HeartIconOutline, DocumentPlusIcon as DocumentPlusOutline  } from '@heroicons/react/24/outline'
import {  HeartIcon as HeartIconSolid, DocumentPlusIcon as DocumentPlusSolid } from "@heroicons/react/24/solid";
import '../_styles/FloorplanItem.css';

const FloorplanItem = ({
  floorplan,
  isFavorite,
  isRowFloorplan,
  isWorksheet,
  toggleFavorite,
  toogleWorksheets,
  isSelected,
  styleProject,
  onClick,
}) => {

  const backgroundColor = styleProject && styleProject["noUi-connect"] && styleProject["noUi-connect"]["background-color"] !== "#000000"
  ? styleProject["noUi-connect"]["background-color"]
  : styleProject && styleProject.titleCardsColors
    ? styleProject.titleCardsColors["color"]
    : "#000000";


  return (
    <div
      className={`grid-floorplan p-4 ${isSelected ? "selected" : ""} ${isRowFloorplan ? 'flex min-h-16 max-h-20' : 'min-h-96'} `}
      onClick={onClick}
    >
      <div className={`grid-floorplan-image ${isRowFloorplan ? 'row-image flex' : ''}`} >
        <img
          src={
            floorplan.fp_image_low
              ? floorplan.fp_image_low[0]
              : floorplan.fp_image_med[0]
          }
          alt={floorplan.fp_name}
        />
      </div>
      <div className={`grid-floorplan-name inline-flex items-center ${isRowFloorplan ? 'justify-start w-2/5' : 'justify-between	w-full'}`}>
        <div
          className="fav-button msg-tooltip"
          onClick={(event) => {
            event.stopPropagation();
            toggleFavorite(); // Llamamos a la función para cambiar favorito
          }}
          data-title="Add to Favorites">
          {!isFavorite ? (
            <HeartIconOutline style={{
              color: `${
               backgroundColor
              }`,
            }}/>

          ) : (
            <HeartIconSolid style={{
              color: `${
                backgroundColor
              }`,
            }}/>
          )}
        </div>
        <h3
          className="ml-1 font-bold"
          style={{ color: `${styleProject &&  styleProject.titleCardsColors && styleProject.titleCardsColors["color"] ? styleProject.titleCardsColors["color"]: ''}` }}
        >
          {floorplan.fp_name.toUpperCase()}
        </h3>
        {!isRowFloorplan && (
        <div className="worksheet-button ml-2 msg-tooltip" onClick={(event) => {
              event.stopPropagation();
              toogleWorksheets();
            }} data-title="Add to Worksheets">
          {!isWorksheet ? (
            <DocumentPlusOutline />
          ): (
            <DocumentPlusSolid />
          )}
        
        </div>
        )}
      </div>
      <DetailsFloorplan floorplan={floorplan} isRowFloorplan={isRowFloorplan} isSelected={isSelected}/>
    </div>
  );
};

export default FloorplanItem;
