const formatStringToCamelCase = (str) => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

export const getStyleObjectFromString = (str) => {
  let styles = [];
  for (const [key, value] of Object.entries(str)) {
    styles.push(`${key}: ${value}`);
  }
  str = styles.join(";");
  const style = {};
  str.split(";").forEach((el) => {
    const [property, value] = el.split(":");
    if (!property) return;

    const formattedProperty = formatStringToCamelCase(property.trim());
    style[formattedProperty] = value.trim();
  });

  return style;
};

export const formatStyles = (obj_str, at = null) => {
  const styles = {};
  
  if(obj_str?.at){
    delete obj_str.at
  }
  if(!obj_str || Object.entries(obj_str).length < 1) return {};

  for(const [prop, value] of Object.entries(obj_str)){
    const checkValue = (value.trim().toLowerCase().startsWith('http')) ? `url('${value}')` : value;
    styles[formatStringToCamelCase(prop.trim())] = checkValue;
  }

  return styles;

}