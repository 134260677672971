/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../../config";
import { saveOrUpdateProject, getProjects, getProjectById } from "../../../db";
import FloorplanItem from "./_parts/FloorplanItem";
import Mastersketch from "../../../plugins/Mastersketch";
import SelectedFloorplanSlide from "./_parts/SelectedFloorplanSlide";
import Loader from "../../Templates/_Parts/Loader";
import {
  Bars4Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import "./_styles/FloorplanIndex.css";

import FilterBar from "./_parts/FilterBar";
import LegendBar from "./_parts/LegendBar";
import CompareFloorplanSlide from "./_parts/CompareFloorplanSlide";
import FloorplatesView from "./_parts/FloorplatesView";
import { isMobile } from "react-device-detect";


const FloorPlans = () => {
  const { projectId } = useParams();
  const [units, setUnits] = useState([]);
  const [clickedUnit, setClickedUnit] = useState(null);
  const [floorplans, setFloorplans] = useState([]);
  const [modelUrl, setModelUrl] = useState("");
  const [sketchStyle, setSketchStyle] = useState({});
  const [nodeGroupName, setNodeGroupName] = useState("");
  const [selectedFloorplan, setSelectedFloorplan] = useState(null);
  const [compareFloorplan, setCompareFloorplan] = useState(null);
  const [unitActiveID, setUnitActiveID] = useState(null);
  const [sketchSetup, setSketchSetup] = useState({});
  const [pointUnit, setPointUnit] = useState({});
  const [unitFloor, setUnitFloor] = useState(null);
  const [floorplates, setFloorplates] = useState([]);
  const [panoramaViews, setPanoramaViews] = useState([]);
  const [selectedUnitRender, setSelectedUnitRender] = useState(null);
  const iframeRef = useRef(null);
  const [currentProject, setCurrentProject] = useState(null);
  const masterSketchRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [favorites, setFavs] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [stylesProject, setStylesProject] = useState([]);
  const [favoritesUnits, setFavoritesUnits] = useState(false);
  const [showFavs, setShowFavs] = useState(false);
  const [isRowFloorplan, setIsRowFloorplan] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [hasViewedFavorites, setHasViewedFavorites] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMasterSketchReady, setIsMasterSketchReady] = useState(false);
  const [isLeftExpanded, setIsLeftExpanded] = useState(false);
  const [isRightExpanded, setIsRightExpanded] = useState(false);
  const [isComparation, setIsComparation] = useState(false);
  const [unitCompareFloorplan, setUnitCompareFloorplan] = useState(null);
  const [compareUnitRender, setCompareUnitRender] = useState(null);
  const [unitPointCompare, setUnitPointCompare] = useState(null);
  const [isFloorplatesView, setIsFloorplatesView] = useState(false);
  const [floorsFilter, setFloorsFilter] = useState([]);
  const useImperativeHandle = useRef(null);
  const filterBarRef = useRef();

  const isTablet = navigator.maxTouchPoints > 0;
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;
  /**
   * Resets the boxes and updates the display of model availability on the master sketch.
   *
   * @async
   * @function resetBoxes
   * @returns {Promise<void>} A promise that resolves when the boxes are reset and the display is updated.
   */
  const resetBoxes = useCallback(async () => {
    const availableUnits = units
      .filter((unit) => unit.unit_status === "available" && (!floorsFilter || floorsFilter.includes(unit.unit_floor)) )
      .map((u) => u.unit_model_name);
    const soldUnits = units
      .filter((unit) => unit.unit_status !== "available" && (!floorsFilter || floorsFilter.includes(unit.unit_floor)) )
      .map((u) => u.unit_model_name);

    if (!showFavs) {
      await updateBuildBoxes(availableUnits, soldUnits, sketchStyle);
      setClickedUnit(null);
      setSelectedFloorplan(null);
      setSelectedUnitRender(null);
      setUnitFloor(null);
    }
  }, [sketchStyle, units, showFavs, favorites, isMasterSketchReady, floorsFilter]);

  /**
   * Initializes the Mastersketch plugin with the given data.
   *
   * @async
   * @function initializeMastersketch
   * @param {Object} data - The data to initialize the Mastersketch plugin with.
   * @returns {Promise<void>} A promise that resolves when the Mastersketch plugin is initialized.
   * @throws {Error} If there is an error initializing the Mastersketch plugin.
   *
   */

  const initializeMastersketch = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const viewer = document.getElementById("api-frame");
        const modelURL = !isMobile && !isTablet ? data.info.modelURL : data.info.modelURLMobile;
        masterSketchRef.current = new Mastersketch(
          viewer,
          modelURL,
          data.sketch,
          data.units,
          data.info.nodeGroupName,
          data.sketch_style,
          projectId
        );
        setIsMasterSketchReady(true);
        await masterSketchRef.current.onClick(
          async (f_id, unit_render, unit_floor, unit_id) => {
            if (!floorplates || floorplates.length === 0) {
              console.warn("No floorplates data available");
              return;
            }

            setClickedUnit(f_id);
            setSelectedUnitRender(unit_render);
            setUnitFloor(unit_floor);
            setUnitActiveID(unit_id);

            const point = await getFloorplate(unit_id);
            setPointUnit(point);
          }
        );
      } catch (error) {
        console.error("Error initializing Mastersketch:", error);
      } finally {
        setLoading(false);
      }
    },
    [floorplates, isMobile, isTablet, projectId]
  );

  const addParams = useCallback((data) => {
    const availableFloorplans = data.floorplans.filter(fp => 
      data.units.some(unit => unit.fp_id === fp.fp_id && unit.unit_status === 'available')
    );

    setUnits(data.units);
    setFloorplates(data.floorplates);
    setModelUrl(data.info?.modelURL);
    setSketchStyle(data.sketch_style);
    setNodeGroupName(data.info?.nodeGroupName);
    setFloorplans(availableFloorplans);
    setPanoramaViews(data.content.views?.view);
    setStylesProject(data.style_project);
    setSketchSetup(data.sketch);
    setCurrentProject(data);
  }, []);

  /**
   * Fetches the project units data from the API and saves it to the database.
   * @async
   * @function fetchProjectUnitsData
   * @returns {Promise<void>} A promise that resolves when the project units data is fetched.
   * @throws {Error} If there is an error fetching the project units data.
   *
   */
  const fetchProjectUnitsData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${config.API_URL}/project/${projectId}/building`,
        {
          headers: {
            apikey: config.API_KEY,
          },
        }
      );

      await saveOrUpdateProject({
        id: projectId,
        units: data.units,
        floorplates: data.floorplates,
        info: data.info,
        content: data.content,
        styles: data.style_project,
        sketch_style: data.sketch_style,
        floorplans: data.floorplans,
        sketch: data.sketch,
      });

      addParams(data);
      await initializeMastersketch(data);
    } catch (error) {
      console.error("Error fetching project units data:", error);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject && currentProject.info && currentProject.units) {
      initializeMastersketch(currentProject);
      setLoading(false);
    }
  }, [currentProject, loading]);

  useEffect(() => {
    /**
     * Loads stored projects and performs necessary operations based on the current project.
     * @returns {Promise<void>} A promise that resolves when the loading and operations are completed.
     */
    const loadStoredProjects = async () => {
      setLoading(true);
      try {
        const storedProjects = await getProjects();
        const currentProject = storedProjects.find(
          (project) =>
            project.id === projectId &&
            project.units &&
            project.units?.length > 0
        );

        if (currentProject) {
          addParams(currentProject);
          setStylesProject(currentProject.styles);
          setPanoramaViews(currentProject.content.views?.view);
          currentProject.favorites
            ? setFavs(currentProject.favorites)
            : setFavs([]);
          currentProject.worksheets
            ? setWorksheets(currentProject.worksheets)
            : setWorksheets([]);

          await initializeMastersketch(currentProject);
        } else {
          await fetchProjectUnitsData();
        }
      } catch (error) {
        console.error("Error loading stored projects:", error);
        await fetchProjectUnitsData();
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      loadStoredProjects();
    }
  }, [projectId, fetchProjectUnitsData, addParams]);

  useEffect(() => {
    if (clickedUnit && !isComparation) {
      const selectedFp = floorplans.find((fp) => fp.fp_id === clickedUnit);
      setUnitFloor(unitFloor);
      setSelectedFloorplan(selectedFp || null);
    }
  }, [clickedUnit, floorplans, unitFloor]);

  /**
   * Toggles the favorite status of a floorplan.
   *
   * @function toggleFavorite
   * @param {string} fp_id - The ID of the floorplan.
   * @returns {void}
   * @throws {Error} - If there is an error toggling the favorite status.
   * @returns {void}
   */
  const toggleFavorite = async (fp_id) => {
    setFavs((prevFavs) => {
      const updatedFavs = prevFavs.includes(fp_id)
        ? prevFavs.filter((fav) => fav !== fp_id)
        : [...prevFavs, fp_id];

      saveOrUpdateProject({
        id: projectId,
        favorites: updatedFavs,
      });

      return updatedFavs;
    });
  };

  /**
   * Handles the click event for the favorites button.
   * @returns {void}
   */

  const handleFavsClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setShowFavs(!showFavs);
      setIsAnimating(false);
    }, 200);
  };

  const toogleWorksheets = async (f, u) => {
    if (!u) {
      const availableUnit = units.find(
        (unit) => unit.fp_id === f.fp_id && unit.unit_status === "available"
      );
      if (availableUnit) {
        f.unit = availableUnit.unit_number;
      } else {
        console.error("No available unit found");
        return; // Detener la ejecución si no se encuentra un unit disponible
      }
    } else {
      // Si se pasa `u`, asignarlo a f.unit
      f.unit = u.unit_number;
    }

    setWorksheets((prevWork) => {
      const updatedWorkshit = prevWork.find((o) => f.fp_id === o.fp_id)
        ? prevWork.filter((fav) => fav.fp_id !== f.fp_id)
        : [...prevWork, f];

      saveOrUpdateProject({
        id: projectId,
        worksheets: updatedWorkshit,
      });

      return updatedWorkshit;
    });
  };

  /**
   * Fetches the favorite units and updates the display of model availability on the master sketch.
   * @returns {void}
   *
   */
  useEffect(() => {
    const fetchFavoriteUnits = async () => {
      if (showFavs) {
        // Cuando se muestran los favoritos
        const favoriteUnitModelNames = units
          .filter(
            (unit) =>
              favorites.includes(unit.fp_id) && unit.unit_status === "available"
          )
          .map((unit) => unit.unit_model_name);

        if (favoriteUnitModelNames.length > 0) {
          setFavoritesUnits(favoriteUnitModelNames);
          await updateBuildBoxes(favoriteUnitModelNames, [], {
            available_color: sketchStyle.available_color,
            sold_color: sketchStyle.unit_color,
            available_opacity: sketchStyle.unit_opacity,
            sold_opacity: sketchStyle.unit_color,
          });
        }
        setHasViewedFavorites(true);
      } else {
        if (hasViewedFavorites) await resetBoxes();
      }
    };

    fetchFavoriteUnits();
  }, [showFavs, favorites, hasViewedFavorites, resetBoxes]);

  /**
   * Updates the display of model availability on the master sketch.
   *
   * @param {*} unitModelNames
   * @param {*} soldUnits
   * @param {*} styles
   */
  const updateBuildBoxes = useCallback(
    async (unitModelNames, soldUnits, styles, floors = false) => {
      if (!isMasterSketchReady) {
        console.warn("MasterSketch is not ready yet");
        return;
      }
      try {
        await masterSketchRef.current.displayModelAvailability(
          unitModelNames,
          soldUnits,
          styles,
          true,
          true,
          floors
        );
        await masterSketchRef.current.handleZoomOut();
      } catch (error) {
        console.error("Error updating build boxes:", error);
      }
    },
    [isMasterSketchReady]
  );

  useEffect(() => {
    const checkInterval = setInterval(() => {
      if (setIsMasterSketchReady) return;

      if (
        masterSketchRef.current &&
        typeof masterSketchRef.current.displayModelAvailability === "function"
      ) {
        setIsMasterSketchReady(true);
        console.log(`Now is ready`);
        clearInterval(checkInterval);
      }
    }, 100);
    return () => clearInterval(checkInterval);
  }, [masterSketchRef, resetBoxes, isMasterSketchReady]);

  /**
   * Closes the selected floorplan slide.
   *
   * @function closeSlide
   * @returns {void}
   */
  const closeSlide = useCallback(async () => {
    if (isComparation) {
      setIsComparation(false);
      setCompareFloorplan(null);
    }

    if (!showFavs) {
      const slideElement = document.querySelector(".selected-floorplan-slide");
      if (slideElement) {
        slideElement.classList.add("hide");
        resetBoxes();
      } else {
        setSelectedFloorplan(null);
      }
    } else {
      if (!setHasViewedFavorites) return;

      await updateBuildBoxes(favoritesUnits, [], {
        available_color: sketchStyle.available_color,
        sold_color: sketchStyle.unit_color,
        available_opacity: sketchStyle.unit_opacity,
        sold_opacity: sketchStyle.unit_color,
      });

      setSelectedFloorplan(null);
    }
  }, [
    showFavs,
    favorites,
    resetBoxes,
    favoritesUnits,
    isComparation,
    sketchStyle,
    hasViewedFavorites,
    updateBuildBoxes,
  ]);

  /**
   * Resets the filter set.
   * If the filter bar reference is available, it calls the handleReset method on the filter bar reference and then calls the handleZoomOut method on the master sketch reference.
   * If the filter bar reference is not available, it logs a warning message.
   * @returns {Promise<void>} A promise that resolves once the filter set is reset.
   */
  const resetFilterSet = async () => {
    if (filterBarRef.current && floorplans.length === 0) {
      filterBarRef.current.handleReset();
      await masterSketchRef.current.handleZoomOut();
    } else {
      console.warn("resetFilters no está definido");
    }
  };

  /**
   * Retrieves the unit information based on the given floorplan.
   *
   * @param {Object} floorplan - The floorplan object.
   * @returns {Promise<void>} - A promise that resolves when the unit information is retrieved.
   * @throws {Error} - If there is an error fetching the unit by floorplan.
   */
  const getUnitByFloorplan = async (floorplan, compare) => {

    if (units.length === 0) return;
    if (!floorplates || floorplates.length === 0) {
      console.warn("No floorplates data available");
      return;
    }

    try {
      let response = await masterSketchRef.current.filterByFloorplanId(
        floorplan.fp_id,
        false,
        compare,
        floorsFilter
      );

      const unitPoint = await getFloorplate(response.getUnitId);
      if (compare) {
        setCompareUnitRender(response.getRenderUnit);
        setUnitCompareFloorplan(response.getUnitFloor);
        setUnitPointCompare(unitPoint);
      } else {
        setSelectedUnitRender(response.getRenderUnit);
        setUnitFloor(response.getUnitFloor);
        setPointUnit(unitPoint);
      }
    } catch (error) {
      console.error("Error fetching unit by floorplan:", error);
    }
  };

  /**
   * Retrieves the floorplate information for a given unit ID.
   *
   * @param {string} unitId - The ID of the unit.
   * @returns {Object} - The floorplate information, including the point and floorplate image.
   */
  const getFloorplate = async (unitId) => {
    let result = {};

    if (!floorplates || floorplates.length === 0) {
      console.warn("No floorplates data available");
      return result;
    }

    floorplates.forEach((f) => {
      f.floorplate_points.point.forEach((p) => {
        if (p.floorplate_unit.length > 0) {
          p.floorplate_unit.forEach((u) => {
            if (u.unit_id === unitId.toString()) {
              result = {
                point: p,
                floorplate_image: f.floorplate_image,
              };
            }
          });
        } else {
          if (p.floorplate_unit.unit_id === unitId.toString()) {
            result = {
              point: p,
              floorplate_image: f.floorplate_image,
            };
          }
        }
      });
    });

    return result;
  };

  const activeComparation = () => {
    setIsComparation(true);
  };

  const toggleLeft = () => {
    if (isLeftExpanded) {
      setIsLeftExpanded(false);
    } else {
      setIsLeftExpanded(true);
      setIsRightExpanded(false);
    }
  };

  const toggleRight = () => {
    if (isRightExpanded) {
      setIsRightExpanded(false);
    } else {
      setIsRightExpanded(true);
      setIsLeftExpanded(false);
    }
  };

  const toggleView = (type) => {
    console.log(`Is Mobile ${type}`);
    setIsMobileView(type);
  };

  return (
    <>
      {loading && <Loader />}
      <div
        className={`grid ${
          isMobile || (isTablet && !isLandscape )? "grid-cols-1" : "grid-cols-2"
        } gap-2 h-screen 
        ${
          isLeftExpanded
            ? "column-expanded-left"
            : isRightExpanded
            ? "column-expanded-right"
            : isMobile || isTablet
            ? "column"
            : "column-centered"
        }`}
      >
        <div
          className={`auto-cols-auto render ${
            isMobile || isTablet ? "h-screen" : ""
          }`}
          style={{
            display: isComparation || isFloorplatesView ? "none" : "flex",
          }}
        >
          <LegendBar
            sketchStyle={sketchStyle}
            updateBuildBoxes={updateBuildBoxes}
            resetBoxes={resetBoxes}
            masterSketchRef={masterSketchRef}
            unitRender={selectedUnitRender}
            floorplan={selectedFloorplan}
            currentProject={currentProject}
            isLeftExpanded={isLeftExpanded}
            isRightExpanded={isRightExpanded}
          />

          <iframe
            key={`sketchfab-${modelUrl}`}
            ref={iframeRef}
            id="api-frame"
            width="100%"
            height="100%"
            src={modelUrl}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; xr-spatial-tracking"
            title="Sketchfab Viewer"
            className={`${isFloorplatesView ? "hidden" : ""}`}
          />
          {!isMobile && !isTablet && (
            <button
              className={`button-slide slide-to-left absolute top-1/2 left-0 transform -translate-y-1/2  ${
                isRightExpanded ? "active" : ""
              }`}
              onClick={() => toggleRight()}
              style={{
                display: isLeftExpanded || isComparation ? "none" : "block",
                left: isRightExpanded ? "1%" : "48.4%",
              }}
            >
              <ChevronLeftIcon
                className="size-6"
                style={{
                  transform: isRightExpanded
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              />
            </button>
          )}
        </div>

        <div
          className="auto-cols-auto floorplates"
          style={{ display: isFloorplatesView ? "flex" : "none" }}
        >
          <FloorplatesView
            floorplates={floorplates}
            floorplans={floorplans}
            units={units}
            sketchStyle={sketchStyle}
            setSelectedUnitRender={setSelectedUnitRender}
            setSelectedFloorplan={setSelectedFloorplan}
            setUnitFloor={setUnitFloor}
            setPointUnit={setPointUnit}
          />
        </div>

        <div
          className={`auto-cols-auto block floorplans ${
            isMobileView ? "show" : "hide"
          } `}
        >
          <FilterBar
            ref={filterBarRef}
            showFavs={showFavs}
            handleFavsClick={handleFavsClick}
            floorplans={floorplans}
            favorites={favorites}
            worksheets={worksheets}
            toggleWorksheets={toogleWorksheets}
            setFloorplans={setFloorplans}
            units={units}
            sketchStyle={sketchStyle}
            masterSketchRef={masterSketchRef}
            sketchSetup={sketchSetup}
            updateBuildBoxes={updateBuildBoxes}
            resetBoxes={resetBoxes}
            isTablet={isTablet}
            isLandscape={isLandscape}
            setFloorsFilter={setFloorsFilter}
          />
          <div className={`grid grid-cols-1 gap-4 right`}>
            <p className="justify-end flex mt-20">
              {floorplans.length} Suites match your search{" "}
            </p>
            {!isMobile && !isTablet && (
              <button
                className={`button-slide slide-to-right absolute top-1/2 right-0 transform -translate-y-1/2 ${
                  isLeftExpanded ? "active" : ""
                }`}
                onClick={() => toggleLeft()}
                style={{
                  display: isRightExpanded || isComparation ? "none" : "block",
                  left: isLeftExpanded ? "98.4%" : "48.4%",
                }}
              >
                <ChevronRightIcon
                  className="size-6"
                  style={{
                    transform: isLeftExpanded
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </button>
            )}
          </div>
          <div
            className={`grid  ${
              isTablet && !isMobile && !isRowFloorplan
              ?  "grid-cols-2"
              : (
              isRowFloorplan || isMobile
                ? "grid-cols-1"
                : isRightExpanded && !isRowFloorplan
                ? "grid-cols-6"
                : "grid-cols-3" )
            } gap-4 mt-1 floorplans-grid ${isComparation ? "comparation" : ""}`}
          >
            {floorplans.length === 0 ? (
              <div className="grid not-found">
                <h2>No results found</h2>
                <button onClick={resetFilterSet} className="btn btn-primary">
                  Reset Filters
                </button>
              </div>
            ) : (
              floorplans
                .filter(
                  (floorplan) =>
                    !showFavs || favorites.includes(floorplan.fp_id)
                )
                .map((floorplan) =>
                  floorplan.fp_status.toString() === "available" ? (
                    <div
                      key={floorplan.fp_id}
                      className={`floorplan-item ${
                        isAnimating ? "hide" : "show"
                      }`}
                    >
                      <FloorplanItem
                        key={floorplan.fp_id}
                        floorplan={floorplan}
                        isRowFloorplan={isRowFloorplan}
                        isSelected={
                          !compareFloorplan &&
                          selectedFloorplan?.fp_id === floorplan.fp_id
                        }
                        floorUnit={null}
                        isFavorite={favorites.includes(floorplan.fp_id)}
                        isWorksheet={worksheets.find(
                          (f) => f.fp_id === floorplan.fp_id
                        )}
                        toggleFavorite={() => toggleFavorite(floorplan.fp_id)}
                        toogleWorksheets={() => toogleWorksheets(floorplan)}
                        styleProject={stylesProject}
                        onClick={() => {
                          if (isComparation) {
                            setCompareFloorplan(floorplan);
                            getUnitByFloorplan(floorplan, true);
                          } else {
                            setSelectedFloorplan(floorplan);
                            getUnitByFloorplan(floorplan, false);
                          }
                        }}
                      />
                    </div>
                  ) : null
                )
            )}
          </div>
        </div>

        {selectedFloorplan && unitFloor && (
          <SelectedFloorplanSlide
            floorplan={selectedFloorplan}
            onClose={closeSlide}
            unitRender={selectedUnitRender}
            setSelectedUnitRender={setSelectedUnitRender}
            isFavorite={favorites.includes(selectedFloorplan.fp_id)}
            floorUnit={unitFloor}
            pointForUnit={pointUnit}
            getFloorplate={getFloorplate}
            setPointUnit={setPointUnit}
            styleProject={stylesProject}
            toggleFavorite={() => toggleFavorite(selectedFloorplan.fp_id)}
            units={units}
            masterSketchRef={masterSketchRef}
            views={panoramaViews}
            activeComparation={activeComparation}
            filterBarRef={filterBarRef}
            contactEmail={currentProject.content.contact_mail}
            floorsFilter={floorsFilter}
          />
        )}

        {compareFloorplan && (
          <CompareFloorplanSlide
            compareFloorplan={compareFloorplan}
            compareUnitRender={compareUnitRender}
            setCompareUnitRender={setCompareUnitRender}
            isFavorite={favorites.includes(compareFloorplan.fp_id)}
            unitCompareFloorplan={unitCompareFloorplan}
            pointForUnit={unitPointCompare}
            styleProject={stylesProject}
            toggleFavorite={() => toggleFavorite(compareFloorplan.fp_id)}
            units={units}
            activeComparation={activeComparation}
            contactEmail={currentProject.content.contact_mail}
          />
        )}
        <div className="buttons-container">


          <div
            className="view-options"
            style={{
              display: isLeftExpanded || isRightExpanded ? "none" : "block",
            }}
          >
            <button
              onClick={() => setIsFloorplatesView(false)}
              className={`btn btn-primary render ${
                !isFloorplatesView ? "active" : ""
              }`}
            ></button>
            <button
              onClick={() => setIsFloorplatesView(true)}
              className={`btn btn-primary floorplate ${
                isFloorplatesView ? "active" : ""
              }`}
            ></button>
          </div>

          <div
            className="change-view"
            style={{
              display: isLeftExpanded || isRightExpanded ? "none" : "block",
            }}
          >
            <button
              onClick={() => {
                setIsRowFloorplan(false);
                if (isMobile || isTablet) {
                  toggleView(isMobileView === "column" ? false : "column");
                }
              }}
              className={`btn btn-primary ${
                !isRowFloorplan || (isMobile || isTablet) && isMobileView === "column"
                  ? "active"
                  : ""
              }`}
            >
              <Squares2X2Icon className="size-6" />
            </button>

            <button
              onClick={() => {
                setIsRowFloorplan(true);
                if (isMobile || isTablet) {
                  toggleView(isMobileView === "row" ? false : "row");
                }
              }}
              className={`btn btn-primary ${
                isRowFloorplan || (isMobile || isTablet) && isMobileView === "row"
                  ? "active"
                  : ""
              }`}
            >
              <Bars4Icon className="size-6" /> 
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloorPlans;
