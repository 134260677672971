import {  useEffect, useState } from "react";

const BtnFavourite = ({favorites, toggleFavorite, styles, home_id, className}) => {
    const [isFav, setIsFav] = useState(favorites.includes(home_id));
    const favouriteIcon = ( (favorites && favorites.includes(home_id)) || isFav) ? styles.favourites['background-image_active'] :  styles.favourites['background-image'];

    useEffect(()=>{
        setIsFav(favorites.includes(home_id))
    }, [favorites, home_id])

    return (
        <span className={`size-6 bg-cover cursor-pointer ${className}`} style={{backgroundImage: `url(${favouriteIcon})`}} onClick={()=>{setIsFav(!isFav); toggleFavorite(home_id)}}></span>
    )
}

export default BtnFavourite