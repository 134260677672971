import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "./_styles/MapComponent.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet/dist/leaflet.css";
import { SuperClustering } from "react-leaflet-supercluster";
import DetailsPoint from "./_parts/PointDetails";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  Bars4Icon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";

const MapComponent = ({ mapCategories, colors, project }) => {
  const [currentZoom, setCurrentZoom] = useState(13);
  const [categories, setCategories] = useState(mapCategories);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const[showCategoriesMobile, setShowCategoriesMobile] = useState(false);
  
  const maxZoomLevel = 20;
  const mapRef = useRef();
  const markerRefs = useRef({});

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      const updateZoom = () => {
        setCurrentZoom(map.getZoom());
      };
      map.on("zoomend", updateZoom);
      return () => {
        map.off("zoomend", updateZoom);
      };
    }
  }, []);

  useEffect(() => {
    setCategories(mapCategories);
  }, [mapCategories]);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      const allPoints = categories.flatMap((cat) =>
        cat.point.map((point) => [point.reflat, point.reflng])
      );
      if (allPoints.length > 0) {
        const bounds = L.latLngBounds(allPoints);
        map.fitBounds(bounds, { padding: [50, 50] });
      }
    }
  }, [categories, selectedCategory]);

  const createIcon = (iconUrl, size = [40, 40]) => {
    return L.icon({
      iconUrl,
      iconSize: size,
      iconAnchor: [15, 40],
      popupAnchor: [0, -40],
    });
  };

  const handleCategoryClick = (category) => {
    const map = mapRef.current;

    if (selectedCategory === category) {
      setSelectedCategory(null);
      setExpandedCategory(null);
      setSelectedPoint(null);
      
      if (map) {
        const allPoints = categories.flatMap((cat) =>
          cat.point.map((point) => [point.reflat, point.reflng])
        );
        if (allPoints.length > 0) {
          const bounds = L.latLngBounds(allPoints);
          map.fitBounds(bounds, { padding: [50, 50] });
        }
      }
    } else {
      // Restablece el zoom del mapa
      if (map) {
        map.setZoom(13); // Ajusta el valor de zoom inicial según sea necesario
      }

      setSelectedCategory(category);
      setExpandedCategory(category);
      setSelectedPoint(null);

      if (map && category.point.length > 0) {
        const bounds = L.latLngBounds(
          category.point.map((point) => [point.reflat, point.reflng])
        );
        map.fitBounds(bounds, { padding: [50, 50] });
      }
    }
  };

  const handlePointClick = (point) => {
    setSelectedPoint(point);
    setShowCategoriesMobile(false)
    const map = mapRef.current;
  
    if (markerRefs.current[point.name]) {
      markerRefs.current[point.name].openPopup();
    }
  
    if (map && map.flyTo) {  // Aseguramos que el mapa esté listo
      map.flyTo([point.reflat, point.reflng], 16, {
        animate: true,
        duration: 0.5,
      });
    }
  };
  
  const handleMarkerClick = (point, category = null) => {
    setSelectedPoint(point);
    setSelectedCategory(category);
    setExpandedCategory(category);
  
    const map = mapRef.current;
  
    if (markerRefs.current[point.name]) {
      markerRefs.current[point.name].openPopup();
    }
  
    if (map && map.flyTo) {  // Aseguramos que el mapa esté listo
      map.flyTo([point.reflat, point.reflng], 16, {
        animate: true,
        duration: 0.5,
      });
    }
  };
  return (
    <div className="flex h-screen">
      <div
        className={`w-1/4 bg-black text-white p-4 categories-container ${MobileView && (showCategoriesMobile ? 'visible' : 'hide')}`}
        style={{
          background: colors?.mapInfoCont
            ? colors.mapInfoCont["background-color"]
            : "#000",
        }}
      >
        <ul className="categories-list">
          {categories.map((category) => (
            <li key={category.name} className="mb-2">
              <button
                className={`w-full text-left hover:text-gray-300 ${
                  selectedCategory === category ? "active-category" : ""
                }`}
                style={{
                  color:
                    selectedCategory === category
                      ? colors?.nameCategory[0]?.color_active
                      : colors?.nameCategory[0]?.color,
                }}
                onClick={() => handleCategoryClick(category)}
              >
                <img
                  src={category.menuIcon}
                  alt={category.name}
                  className="inline-block mr-2"
                />
                {category.name.replaceAll("_", " ")}
              </button>
              {expandedCategory === category && (
                <ul className="subcategories ml-4 mt-2">
                  {category.point.map((point) => (
                    <li
                      key={`${point.name}-${point.reflat}-${point.reflng}`}
                      className="mb-2"
                    >
                      <button
                        className={`w-full text-left text-sm hover:text-gray-300 ${
                          selectedPoint === point ? "active-point" : ""
                        }`}
                        style={{
                          color:
                            selectedPoint === point
                              ? colors?.nameCategory[0]?.color_active
                              : colors?.nameCategory[0]?.color,
                        }}
                        onClick={() => handlePointClick(point)}
                      >
                        {point.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="w-3/4 map-container">
        <MapContainer
          center={[project?.reflat, project?.reflng]}
          zoom={13}
          style={{ height: "100%", width: "100%" }}
          ref={mapRef}
          maxZoom={maxZoomLevel}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {project && (
            <Marker
              key={`${project.name}-${project.reflat}-${project.reflng}`}
              position={[project.reflat, project.reflng]}
              icon={createIcon(
                selectedPoint?.name === project.name
                  ? project.markerIconActive
                  : project.markerIcon,
                [85, 80]
              )}
              zIndexOffset={999}
              ref={(ref) => (markerRefs.current[project.name] = ref)}
              eventHandlers={{
                click: () => handleMarkerClick(project, selectedCategory),
              }}
            >
                 <Popup autoOpen={selectedPoint?.name === project.name}>
                    <DetailsPoint point={project} colors={colors} isProject={true}/>
                  </Popup>
            </Marker>
          )}
          
          {selectedCategory
            ? selectedCategory.point.map((point) => (
                <Marker
                  key={`${point.name}-${point.reflat}-${point.reflng}`}
                  position={[point.reflat, point.reflng]}
                  icon={createIcon(
                    selectedPoint?.name === point.name
                      ? selectedCategory.markerIconActive
                      : selectedCategory.markerIcon,
                    [40, 40]
                  )}
                  zIndexOffset={selectedPoint?.name === point.name ? 1000 : 0}
                  ref={(ref) => (markerRefs.current[point.name] = ref)}
                  eventHandlers={{
                    click: () => handleMarkerClick(point, selectedCategory),
                  }}
                >
                  <Popup autoOpen={selectedPoint?.name === point.name}>
                    <DetailsPoint point={point} colors={colors} />
                  </Popup>
                </Marker>
              ))
            : categories.flatMap((category) =>
                category.point.map((point) => (
                  <Marker
                    key={`${point.name}-${point.reflat}-${point.reflng}`}
                    position={[point.reflat, point.reflng]}
                    icon={createIcon(category.markerIcon, [40, 40])}
                    zIndexOffset={selectedPoint?.name === point.name ? 1000 : 0}
                    ref={(ref) => (markerRefs.current[point.name] = ref)}
                    eventHandlers={{
                      click: () => handleMarkerClick(point, category),
                    }}
                  >
                    <Popup autoOpen={selectedPoint?.name === point.name}>
                      <DetailsPoint point={point} colors={colors} />
                    </Popup>
                  </Marker>
                ))
              )}
        </MapContainer>

      <div className="responsive-buttons">
            <button
            onClick={() => setShowCategoriesMobile(false)}
            className={`btn btn-primary ${!showCategoriesMobile ? 'active' : ''}`}
          >
            <Squares2X2Icon className="size-6" />
          </button>
          <button
            onClick={() => setShowCategoriesMobile(true)}
            className={`btn btn-primary ${showCategoriesMobile ? 'active' : ''}`}
          >
            <Bars4Icon className="size-6" />
          </button>
      </div>
      </div>
      
    </div>
    
  );
};

export default MapComponent;
