import { getHomesByProject, getLotsByProjectPhase } from "../../services/getDataModelLw"
import SFHandler from "../LowriseSketchfabHandler"

export default async function newSFIntsance(projectId, phase, styles = null){
    if(typeof SFHandler === 'function'){
        const CONFIG_INIT = {
            options: {
                autostart: 1,
                ui_controls: 0,
                ui_infos: 0,
                ui_inspector:0,
                ui_watermark: 0,
                ui_loading: 1,
                ui_stop: 0,
                preload: 1,
                ui_color: 'ffffff',
                camera: 0
            },
            distinctives: {
                lots: phase?.distinctives?.lots?.split(','),
                houses: phase?.distinctives?.houses?.split(',')
            },
            camera:{
                desktop: {
                    position: phase?.central_camera?.desktop?.position?.split(','),
                    target: phase?.central_camera?.desktop?.target?.split(','),
                }, 
                mobile: {
                    position: phase?.central_camera?.mobile?.position?.split(','),
                    target: phase?.central_camera?.mobile?.target?.split(','),
                }
            }
        }

        if(phase?.model_url){
            const instance = new SFHandler(phase?.model_url, CONFIG_INIT)
            const lots = await getLotsByProjectPhase(projectId, phase.phase_id)
            const homes = await getHomesByProject(projectId)

            if(!lots) return;

            const props = await lots.map(
                e => {
                    let info = e?.lot_model_name?.match(/\d+/g)
                    let modelName = e?.lot_model_name
                    let status = e?.lot_status
                    let type = (!['sold', 'onhold'].includes(status)) ? e?.lot_type_home.trim().toLocaleLowerCase().replaceAll(/[-\s]/g, '_') : 'sold';
                    let collectionID = e?.lot_collection_id
                    let collection = e?.lot_collection
                    let homesAvailable = homes.filter(home => home.home_collection_id === collectionID && !e.lot_homes_to_hide.includes(home.home_id)).map(h => {
                        
                        let homePlans = h.home_plans.map(p => {
                            return {
                                name: p?.name_plan,
                                image: p?.image?.url,
                            }
                        })
                        let elevations = h?.home_elevations.map(el => {
                            return {
                                name: el?.elevation_name,
                                description: el?.description,
                                image: el?.image,
                                price: el?.price,
                                size: el?.size,
                                bed: el?.bedrooms,
                                bath: el?.bathrooms,
                                garage: el?.garage,
                            }
                        })
    
                        return {
                            id: h?.home_id,
                            name: h?.home_name,
                            price: h?.home_price,
                            size: h?.home_size_default,
                            bed: h?.home_bed_default,
                            bath: h?.home_bath_default,
                            rnk: h?.home_rnk,
                            garage: h?.home_garage_default,
                            homePlans,
                            elevations
                        }
                    } )
                    
                    return {
                        'blockNumber': info[1] ?? undefined,
                        'unitNumber': info[0] ?? undefined,
                        modelName,
                        status,
                        type,
                        collection,
                        collectionID,
                        homesAvailable
                    }
                    
                }
            )
            if(styles){
                instance.setColors({
                    move_in_ready: {
                        rgb: SFHandler.convertHex(styles?.available_color),
                        opacity: Number(styles?.available_opacity)
                    },
                    // sold: {
                    //     rgb: SFHandler.convertHex(XML.querySelector('hasModel')?.getAttribute('sold_color')),
                    //     opacity: Number(XML.querySelector('hasModel')?.getAttribute('sold_opacity'))
                    // }
                })
            }
            instance.setProps(props)
            // instance.setClickEvent((lot) => {
            //     if (lot?.homesAvailable) {
            //         console.log(lot.homesAvailable)
            //         // previewHomesOnLot(lot?.modelName);
            //     }
            // })
            return instance
        }else{
            return null;
        }
    }
}