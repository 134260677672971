import React, { useEffect, useState } from "react";
import {
  ArrowPathIcon,
  EyeIcon,
  EyeSlashIcon,
  QuestionMarkCircleIcon as QuestionMarkCircleIconSolid
} from "@heroicons/react/24/solid";

import { QuestionMarkCircleIcon as QuestionMarkCircleIconOutline } from "@heroicons/react/24/outline";

import '../_styles/LegendBar.css';
const LegendBar = ({
  sketchStyle,
  updateBuildBoxes,
  resetBoxes,
  masterSketchRef,
  unitRender,
  floorplan,
  currentProject,
  isLeftExpanded,
  isRightExpanded
}) => {
  const [hideAvailability, setHideAvailability] = useState(false);
  const [switchOn, setSwitchOn] = useState(false);
  const [infoImage, setInfoImage] = useState(true);

  const hideBoxes = () => {
    setHideAvailability(!hideAvailability);
  };

  if (hideAvailability) {
    updateBuildBoxes([], [], sketchStyle);
  }

  if (switchOn && !hideAvailability) {
    resetBoxes();
    setSwitchOn(false);
  }

  useEffect(() => {
    if(infoImage) {
      setTimeout(() => {
        setInfoImage(false);
      }, 7000);
    }
  }, [unitRender, infoImage, isLeftExpanded]);

  return (
    <>
    <div className="legend" style={{left: isLeftExpanded ? '50%' : '25%', display: isRightExpanded ? 'none' : ''} }>
      <div
        className="hide-availability button-action msg-tooltip"
        data-title="Availability"
        onClick={() => {
          hideBoxes();
          setSwitchOn(true);
        }}
      >
        {!hideAvailability ? (
          <EyeSlashIcon className="size-6" />
        ) : (
          <EyeIcon className="size-6" />
        )}
      </div>
      <div
        className="button-action msg-tooltip"
        data-title="Reset View"
        onClick={() => masterSketchRef.current.handleZoomOut()}
      >
        <ArrowPathIcon className="size-6" />
      </div>
      <div className="info-color mr-2">
        <span
          className="round-color"
          style={{ background: sketchStyle.available_color }}
        ></span>{" "}
        {floorplan ? floorplan.fp_name : "Available"}
      </div>
      <div className="info-color">
        <span
          className="round-color"
          style={{
            background: unitRender
              ? sketchStyle.unit_color
              : sketchStyle.sold_color,
          }}
        ></span>{" "}
        {unitRender ? unitRender : "Sold"}
      </div>
      <div
        className="button-action msg-tooltip"
        data-title="Navigation Help"
        onClick={() => setInfoImage(!infoImage)}>
        {infoImage ? (
          <QuestionMarkCircleIconSolid className="size-6" />
        ) : (
          <QuestionMarkCircleIconOutline className="size-6" />
        )}

      </div>

    </div>
    
    <div className={`help-guide absolute ${infoImage ? 'show' : 'hide'}`} style={{background: `url(${currentProject?.info?.controls_navigation_desk})` }} ></div>
    </>
    
  );
};

export default LegendBar;
