/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
//import { getStyleObjectFromString } from '../../../plugins/FormatStyle.js';
import './contact.css';

const ContactHeader = ({email, phone}) => {
    return (
        <div id="contactBurguer" className="contact">
        {phone && (
        <div className="contactHalf" id="contactHalfPhoneBurguer">
            <div className="contactHalfLabel">
                P:
            </div>
            <a className="contactHalfContent" id="phoneContentBurguer" href="{phone}">
                {phone}
            </a>
        </div>
        )}
        {email && (
            <div className="contactHalf" id="contactHalfEmailBurguer">
                <div className="contactHalfLabel">
                    E:
                </div>
                <a className="contactHalfContent" id="emailContentBurguer" href={`mailto:${email}`}>{email}</a>
            </div>
        )}
    </div>
    )}

 

  
export default ContactHeader;