import { SplideSlide } from '@splidejs/react-splide';
import { useState } from 'react';
import BtnFavourite from './BtnFavourite';

const HomePreviewCard = ({home, styles, favorites, toggleFavorite, setShowHome, setCurrentHome}) => {
    const [loaded, setLoaded] = useState(false);
    const img = home.elevations[0].image?.sizes?.thumbnail ?? home.elevations[0].image?.url
    
    return (
        <SplideSlide>
            <article className="lowrise__siteplan__homecard pb-5 w-full">
                <figure className="aspect-[4/3]">
                    <img
                        className={`w-full h-full object-cover object-center transition-opacity duration-300 ${
                        loaded ? "opacity-100" : "opacity-0"
                        }`}
                        src={img}
                        alt=""
                        onLoad={() => setLoaded(true)}
                        onClick={()=>{console.log(home); setCurrentHome(home);  setShowHome(true)}}
                    />
                </figure>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-3 py-3">
                    <BtnFavourite favorites={favorites} toggleFavorite={toggleFavorite} home_id={home.id} styles={styles} />
                    <h4 className="font-bold uppercase">{home.name}</h4>
                    </div>
                    <div className="border-t border-t-black pt-1 grid grid-cols-[1fr_1fr_1fr_auto] gap-1">
                        <div className="homecard__info flex flex-col">
                            <span>BEDS</span>
                            <span>{home.bed ?? 0}</span>
                        </div>
                        <div className="homecard__info flex flex-col">
                            <span>BATHS</span>
                            <span>{home.bath ?? 0}</span>
                        </div>
                        <div className="homecard__info flex flex-col">
                            <span>PARKING</span>
                            <span>{home.garage ?? 0}</span>
                        </div>
                        <div className="homecard__info flex flex-col">
                            <span>AREA (SQFT)</span>
                            <span>{home.size ?? 0}</span>
                        </div>
                    </div>
                </div>
            </article>
        </SplideSlide>
    )
}

export default HomePreviewCard;