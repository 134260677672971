/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import React from "react";
import "./BrokerAssets.css";
import { getStyleObjectFromString } from "../../../plugins/FormatStyle.js";

const BrokerAssets = ({ section, colors }) => {
  return (
    <div
      className="broker-container"
      style={{
        background: `url(${section.bgImageDsk ? section.bgImageDsk : "#fff"}`,
      }}
    >
      <div className="items-list">
        {section?.assets &&
          section.assets.asset &&
          section.assets.asset.map((a, i) => (
            <div
              className="item"
              key={i}
              style={getStyleObjectFromString(colors.assetsColor)}
            >
              <a
                href={a.assetUrl}
                target="_blank"
                style={getStyleObjectFromString(colors.subtitle)}
              >
                {a.assetName.replace(/&amp;/g, "&")}
              </a>
              <div
                className="icon-asset"
                style={{
                  background: `url(${colors.iconAssets["background-image"]})`,
                }}
              ></div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BrokerAssets;
